import React, { useContext, useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import * as dayjs from 'dayjs'
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  PageHeader,
  Row,
  Select,
  Table,
  Tooltip
} from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import UserSelect from '../../../components/UserSelect'

import { getManufacturedParts, getManufacturedPartsDataset } from '../../../calculators/manufactured-parts'

import advantageBuildingOptions from '../../../data/advantage/options'
import advantageBuildingAmounts from '../../../data/advantage/amounts'
import advantageBuildingAddons from '../../../data/advantage/addons'

import integrityBuildingOptions from '../../../data/integrity/options'
import integrityBuildingAmounts from '../../../data/integrity/amounts'
import integrityBuildingAddons from '../../../data/integrity/addons'

import integrityCattleBuildingOptions from '../../../data/integrity/cattle/options'
import integrityCattleBuildingAmounts from '../../../data/integrity/cattle/amounts'
import integrityCattleBuildingAddons from '../../../data/integrity/cattle/addons'

import { generateTableColumns, cloneObjectExcludingKeys } from '../../../helpers'

import { userContext } from '../../../context/User.js'

const isProduction = process.env.REACT_APP_ENV === 'production'

export default function EditBuildingEstimate (props) {
  const nonCalcFields = ['status', 'dealerId', 'buildingType', 'title', 'customerName', 'customerAddress', 'requestedPickUpDate', 'notes']
  const user = useContext(userContext)
  const isAdmin = user.permissions.includes('manage-estimates')
  const [form] = Form.useForm()
  const [buildingType, setBuildingType] = useState('advantage')
  const [buildingOptions, setBuildingOptions] = useState(advantageBuildingOptions)
  const [buildingAmounts, setBuildingAmounts] = useState(advantageBuildingAmounts)
  const [buildingAddons, setBuildingAddons] = useState(advantageBuildingAddons)
  const [manufacturedParts, setManufacturedParts] = useState(getManufacturedPartsDataset(buildingType))
  const [parts, setParts] = useState([])
  const [totals, setTotals] = useState({
    sqft: 0,
    suggestedPrice: 0,
    pricePerSqFt: 0,
    weight: 0,
    hours: 0,
    cost: 0
  })

  const calculateEstimate = () => {
    calculateMainCoversQty()

    const values = form.getFieldsValue(true)

    const calculatedParts = getManufacturedParts(values, buildingType)
    const engineerDrawingsNeeded = values['engineer-sealed-drawings-needed-for-the-building-only']
    let calculatedTotals = {}

    calculatedTotals.cost = (calculatedParts.reduce((a, b) => a + (b.cost || 0), 0) ?? 0).toFixed(2)
    calculatedTotals.hours = ((calculatedParts.reduce((a, b) => a + (b.hours || 0), 0) * 0.95) ?? 0).toFixed(2)
    calculatedTotals.sqft = Math.ceil((parseInt(values.buildingLength) * parseFloat(values.buildingWidthNumber)) ?? 0)

    calculatedTotals.suggestedPrice = (Math.ceil(
      Math.ceil(
      (
        (
          (calculatedTotals.cost / 0.55) + 
          (engineerDrawingsNeeded ? 1 : 0) *
          (1250 +
            (calculatedTotals.sqft > 4500 ? 750 : 0) +
            (calculatedTotals.sqft > 8000 ? 500 : 0) +
            (calculatedTotals.sqft > 12000 ? 500 : 0) +
            (calculatedTotals.sqft > 15000 ? 500 : 0) +
            (calculatedTotals.sqft > 20000 ? 500 : 0) +
            (calculatedTotals.sqft > 25000 ? 1500 : 0)
          )
        ) + 105 * (
          (buildingType === 'advantage' ? 1 : 0) +
          (buildingType === 'integrity' ? Math.ceil(calculatedTotals.sqft / 3000) + values.solidEndwallKit : 0) +
          (buildingType === 'integrity-cattle' ? Math.ceil(calculatedTotals.sqft / 3000) + values.doorEndwallKit : 0)
        )
      ) / 0.7) / 10) * 10).toFixed(2)

    calculatedTotals.discountPercentage = parseFloat(values.discountPercentage)
    calculatedTotals.amountDue = (calculatedTotals.suggestedPrice - ((parseFloat(calculatedTotals.discountPercentage) / 100) * calculatedTotals.suggestedPrice)).toFixed(2)
    calculatedTotals.deposit = ((40 / 100) * calculatedTotals.amountDue).toFixed(2)
    calculatedTotals.amountDueAtPickup = (calculatedTotals.amountDue - calculatedTotals.deposit).toFixed(2)
    calculatedTotals.pricePerSqFt = ((calculatedTotals.suggestedPrice / calculatedTotals.sqft) ?? 0).toFixed(2)
    calculatedTotals.weight = (calculatedParts.reduce((a, b) => a + (b.weight || 0), 0) ?? 0).toFixed(2)

    const results = { totals: calculatedTotals, parts: calculatedParts }

    setParts(results.parts)
    setTotals(results.totals)

    return results
  }

  const calculateMainCoversQty = () => {
    let qty = 0
    const values = form.getFieldsValue(true)
    const buildingLength = parseInt(values.buildingLength)
    const rafterSpacing = parseInt(values.rafterSpacing)
    
    switch (values.mainCoverTerminationStyle) {
      case 'individual-bay-keder':
        qty = (buildingLength / rafterSpacing)
        break
      case 'hybrid':
      case 'sectional-keder':
        switch (rafterSpacing) {
          case 18:
            qty = buildingLength <= 54 ? 1 : (buildingLength / 54)
            break
          case 6:
          case 12:
          case 20:
            qty = buildingLength <= 60 ? 1 : (buildingLength / 60)
            break
          case 4:
          case 8:
          case 16:
            qty = buildingLength <= 64 ? 1 : (buildingLength / 64)
            break
          case 5:
          case 10:
          case 14:
            qty = buildingLength <= 70 ? 1 : (buildingLength / 70)
            break
        }
        break
      case 'monolithic':
        switch (rafterSpacing) {
          case 8:
          case 12:
          case 16:
            qty = buildingLength <= 96 ? 1 : (buildingLength / 96)
            break
          case 14:
            qty = buildingLength <= 98 ? 1 : (buildingLength / 98)
            break
          case 4:
          case 5:
          case 10:
            qty = buildingLength <= 100 ? 1 : (buildingLength / 100)
            break
          case 6:
            qty = buildingLength <= 102 ? 1 : (buildingLength / 102)
            break
        }
        break
    }

    qty = Math.ceil(qty)

    if (form && form.setFieldValue) {
      form.setFieldValue('numberOfMainCovers', qty)
    }
  }

  const getInitialValues = () => {
    const initialValues = Object.assign(
      {
        title: props.data?.title,
        buildingLength: 0,
        buildingType: props.data?.buildingType ?? 'advantage',
        dealerId: props.data?.dealerId ?? user.id,
        customerName: props.data?.customerName ?? '',
        customerAddress: props.data?.customerAddress ?? '',
        requestedPickUpDate: props.data?.requestedPickUpDate ? dayjs(props.data.requestedPickUpDate) : '',
        status: props.data?.status ?? 'draft',
        discountPercentage: props.data?.discountPercentage ?? 30.00,
        notes: props.data?.notes ?? ''
      },
      buildingOptions.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {}),
      buildingAmounts.reduce((acc, cur) => ({ ...acc, [cur.name]: cur.value }), {}),
      buildingAddons.reduce((acc, cur) => ({ ...acc, [cur.slug]: cur.checked }), {}),
      props.data?.inputData ?? {}
    )

    return initialValues
  }

  const handlePartAdd = () => {
    const newData = parts.concat([{
      requiredQty: 0,
      addQty: 0
    }])

    setParts(newData)
  }

  const handleCancel = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    form.resetFields()
    setTotals({
      sqft: 0,
      suggestedPrice: 0,
      pricePerSqFt: 0,
      weight: 0,
      hours: 0,
      cost: 0
    })
    if (props.onCancel) {
      props.onCancel()
    }
  }

  const handlePartChange = (value, item, index, record) => {
    let newData = [...parts]
    let newPart = manufacturedParts.find(p => p.partNumber === value)

    index = newData.findIndex(p => p.partNumber === record.partNumber)

    newData.splice(index, 1)
    newData.push(Object.assign({ requiredQty: 0, addQty: record.addQty }, newPart))

    setParts(newData)
  }

  const onBuildingTypeChange = (type) => {
    setBuildingType(type)
    setManufacturedParts(getManufacturedPartsDataset(type))
    
    switch(type) {
      case 'integrity':
        setBuildingOptions(integrityBuildingOptions)
        setBuildingAmounts(integrityBuildingAmounts)
        setBuildingAddons(integrityBuildingAddons)
        break
      case 'integrity-cattle':
        setBuildingOptions(integrityCattleBuildingOptions)
        setBuildingAmounts(integrityCattleBuildingAmounts)
        setBuildingAddons(integrityCattleBuildingAddons)
        break
      case 'advantage':
      default:
        setBuildingOptions(advantageBuildingOptions)
        setBuildingAmounts(advantageBuildingAmounts)
        setBuildingAddons(advantageBuildingAddons)
    }
  }

  const onChange = (updated, values) => {
    const updatedKeys = Object.keys(updated || {})
    const updatedOptionsWithHiddenFields = buildingOptions.filter(item => item.hiddenField && updatedKeys.includes(item.name))

    if (updatedOptionsWithHiddenFields.length) {
      updatedOptionsWithHiddenFields.forEach(option => {
        const selectedOption = option.options.find(item => item.value === updated[option.name])

        if (form && form.setFieldValue) {
          form.setFieldValue(option.hiddenField, selectedOption?.hiddenfieldvalue)
        }
      })
    }

    if (!Object.keys(values).length || !updatedKeys.some(key => nonCalcFields.includes(key))) {
      calculateEstimate()
    }
  }

  const onFinish = (values) => {
    return props.onFinish({ ...values, parts, totals, type: 'building' })
  }

  useEffect(() => {
    if (props.data) {
      if (props.data.inputData?.buildingType) {
        onBuildingTypeChange(props.data.inputData.buildingType)
      }

      if (props.data.parts) {
        setParts(props.data.parts)
      }
  
      if (props.data.totals) {
        setTotals(props.data.totals)
      }
    }
  }, [])

  useEffect(() => {
    if (form && form.setFieldsValue) {
      const initialValues = getInitialValues()
      const formValues = cloneObjectExcludingKeys(initialValues, nonCalcFields)
      form.setFieldsValue(formValues)
      onChange(initialValues, props.data ? initialValues : {})
    }
  }, [ buildingType ])

  return (
    <>
      <PageHeader
        title={ (props.data ? 'Edit' : 'New') + ' Building Estimate' }
        onBack={ props.onCancel }
      />

      <Form
        form={ form }
        initialValues={ getInitialValues() }
        layout="vertical"
        onValuesChange={ onChange }
        onFinish={ onFinish }
        scrollToFirstError
      >
        { isAdmin &&
          <Row gutter={24}>
            <Col span={3}>
              <Form.Item name="status" label="Estimate Status">
                <Select options={[
                  { label: 'Draft', value: 'draft' },
                  { label: 'Pending', value: 'pending' },
                  { label: 'Approved', value: 'approved' },
                  { label: 'Ordered', value: 'ordered' },
                  { label: 'Complete', value: 'complete' },
                ]} />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item name="dealerId" label="Assigned Dealer">
                <UserSelect />
              </Form.Item>
            </Col>
          </Row>
        }

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="buildingType" label="Building Type">
              <Select onChange={onBuildingTypeChange} options={
                // Don't show Integrity or Integrity Cattle on production yet
                [
                  { label: 'Advantage', value: 'advantage' },
                  { label: 'Integrity', value: 'integrity' }
                ].concat(!isProduction ?
                  [
                    { label: 'Integrity Cattle', value: 'integrity-cattle' }
                  ] :
                [])
              } />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="title" label="Estimate Title">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="customerName" label="Customer Name">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="customerAddress" label="Customer Address">
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="requestedPickUpDate" label="Requested Pick-Up Date">
              <DatePicker format={'MM/DD/YYYY'}/>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="buildingLength" label="Building Length" rules={[ { required: true } ]}>
              <InputNumber />
            </Form.Item>
          </Col>

          {
            buildingOptions.map((opt, i) => {
              return (
                <Col key={i} span={6}>
                  <Form.Item name={opt.name} label={opt.label} tooltip={opt.info}>
                    <Select options={opt.options} />
                  </Form.Item>

                  { opt.hiddenField &&
                    <Form.Item name={ opt.hiddenField } hidden>
                      <Input type="hidden" />
                    </Form.Item>
                  }
                </Col>
              )
            })
          }
        </Row>

        <Row gutter={24}>
          {
            buildingAmounts.map((opt, i) => {
              return (
                <Col key={i} span={6}>
                  <Form.Item name={opt.name} label={opt.label} tooltip={opt.info}>
                    <InputNumber min={opt.min} max={opt.max} />
                  </Form.Item>
                </Col>
              )
            })
          }
        </Row>

        <Row gutter={24}>
          {
            buildingAddons.map((opt, i) => {
              return (
                <Col key={i} span={8}>
                  <Form.Item name={opt.slug} valuePropName="checked" style={{ margin: '0px' }}>
                    <Checkbox>
                      <span>{opt.name}</span>
                      { opt.info &&
                        <Tooltip title={opt.info}><QuestionCircleOutlined /></Tooltip>
                      }
                    </Checkbox>
                  </Form.Item>
                </Col>
              )
            })
          }
        </Row>

        <div>
          <Table
            bordered
            dataSource={
              parts.map((p, i) => Object({ ...p, key: i }))
            }
            pagination={ false }
            size="small"
            rowKey="key"
            rowClassName={ (item) => (!item.id) ? 'no-expand' : null }
            columns={[
              {
                title: 'Part / Part Number',
                dataIndex: 'name',
                render: (item, record, index) => {                  
                  if (record.requiredQty) {
                    let displayDescription = record.description
                    let displayPartNumber = record.partNumber ?? `ID #${record.id}`
  
                    if (record.alternate && form && form.getFieldValue) {
                      const fieldValue = form.getFieldValue(record.alternate.field)

                      if (fieldValue === record.alternate.value) {
                        if (record.alternate.properties.description) {
                          displayDescription = record.alternate.properties.description
                        }

                        if (record.alternate.properties.partNumber) {
                          displayPartNumber = record.alternate.properties.partNumber
                        }
                      }
                    }

                    return (
                      <div className="part">
                        <span className="part-name">{ displayDescription }</span>
                        <span className="part-number">{ displayPartNumber }</span>
                      </div>
                    )
                  }

                  return (
                    <Form.Item>
                      <Select
                        showSearch
                        placeholder="Select a part"
                        optionFilterProp="children"
                        filterOption={ (input, option) => {
                          return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }}
                        options={
                          manufacturedParts
                            // Filter out parts that are already accounted for (but keep currently selected part)
                            .filter(part => !parts.map(a => a.partNumber).includes(part.partNumber) || part.partNumber === record.partNumber)

                            // Format option field
                            .map(part => {
                              let displayDescription = part.description
                              let displayPartNumber = part.partNumber ?? `ID #${part.id}`
            
                              if (part.alternate && form && form.getFieldValue) {
                                const fieldValue = form.getFieldValue(part.alternate.field)
          
                                if (fieldValue === part.alternate.value) {
                                  if (part.alternate.properties.description) {
                                    displayDescription = part.alternate.properties.description
                                  }
          
                                  if (part.alternate.properties.partNumber) {
                                    displayPartNumber = part.alternate.properties.partNumber
                                  }
                                }
                              }

                              return {
                                label: `${displayDescription} (${displayPartNumber})`,
                                value: part.partNumber ?? part.id
                              }
                            })
                        }
                        onChange={ (value, item) => handlePartChange(value, item, index, record) }
                        style={{ display: 'block' }}
                        value={ record.partNumber }
                      />
                    </Form.Item>
                  )
                }
              },
              {
                title: 'Required Quantity',
                dataIndex: 'requiredQty',
                render: (item, record, index) => (
                  <NumberFormat value={record.requiredQty} displayType={'text'} thousandSeparator={true} />
                )
              },
              {
                title: 'Additional Quantity',
                dataIndex: 'addQty',
                render: (item, record, index) => (
                  <Form.Item name={['additions', record.partNumber]} initialValue={ record.addQty }>
                    <InputNumber />
                  </Form.Item>
                )
              }
            ]}
            expandable={{
              rowExpandable: (item) => isAdmin,
              expandedRowRender: (item) => {
                return (
                  <>
                    <Table
                      columns={ generateTableColumns(['weight', 'hours', 'cost']) }
                      dataSource={ [ item ] }
                      pagination={ false }
                      rowKey={ (item) => 'partsInfo_' + item.id }
                      rowClassName={ (item) => (!item.parts.length) ? 'no-expand' : null }
                      size='small'
                      expandable={{
                        expandedRowRender: (item) => {
                          return (
                            <>
                              <h3>Parts</h3><br />
                              <Table
                                columns={ generateTableColumns(['id', 'description', 'weight', 'galv', 'steelMrk', 'steelDrop', 'steelCost', 'fastener', 'cover', 'labor', 'totalMrk']) }
                                dataSource={ item.parts }
                                pagination={ false }
                                rowKey={ (item) => 'partsInfoParts_' + item.id }
                                rowClassName={ (item) => (!item.materials.length) ? 'no-expand' : null }
                                size='small'
                                expandable={{
                                  expandedRowRender: (item) => {
                                    return (
                                      <>
                                        <h4>Materials</h4><br />
                                        <Table
                                          columns={ generateTableColumns(['id', 'description', 'unitType', 'unitDivisor', 'pricePerPound', 'pricePerUnit', 'poundsPerStock', 'poundsPerUnit']) }
                                          dataSource={ item.materials }
                                          pagination={ false }
                                          rowKey={ (item) => 'partsInfoMaterials_' + item.id }
                                          size='small'
                                        />
                                      </>
                                    )
                                  }
                                }}
                              />
                            </>
                          )
                        }
                      }}
                    />
                  </>
                )
              }
            }}
          />

          <Button
            onClick={ handlePartAdd }
            type="primary"
            style={{
              marginBottom: 16
            }}>Add a Part</Button>
        </div>

        { isAdmin &&
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item name="discountPercentage" label="Retail Price Discount Percentage">
                <InputNumber min="0" max="100" step=".01" />
              </Form.Item>
            </Col>
          </Row>
        }

        <Row>
          <Card title="Totals" style={{ display: 'inline-block' }}>
            <p><b>Sqft</b>: { <NumberFormat value={totals.sqft} displayType={'text'} thousandSeparator={true} decimalScale={0} /> }</p>
            <p><b>Shipping Weight</b>: { <NumberFormat value={totals.weight} displayType={'text'} thousandSeparator={true} /> }</p>
            <p><b>Suggested Retail Price</b>: { <NumberFormat value={totals.suggestedPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
            { isAdmin && <p><b>Dealer Discount</b>: { <NumberFormat value={totals.discountPercentage} displayType={'text'} suffix={'%'} /> }</p> }
            <p><b>Dealer Price</b>: { <NumberFormat value={totals.amountDue} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
            <p><b>Price Per Sqft</b>: { <NumberFormat value={totals.pricePerSqFt} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
            <p><b>Deposit Required</b>: { <NumberFormat value={totals.deposit} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
            <p><b>Amount Due at Pickup</b>: { <NumberFormat value={totals.amountDueAtPickup} displayType={'text'} thousandSeparator={true} prefix={'$'} /> }</p>
          </Card>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="notes"
              label="Note"
              tooltip="Add any additional notes or comments about this estimate"
            >
              <Input.TextArea
                rows={4}
                placeholder="Enter any additional notes here..."
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col>
            <Form.Item>
              <Button onClick={ handleCancel }>Cancel</Button>
            </Form.Item>
          </Col>

          { !isAdmin &&
            <>
              <Col>
                <Form.Item name="status">
                  <Button htmlType="submit" onClick={ (e) => form.setFieldValue('status', 'draft') }>Save As Draft</Button>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item name="status">
                  <Button type="primary" htmlType="submit" onClick={ (e) => form.setFieldValue('status', 'pending') }>Submit Order</Button>
                </Form.Item>
              </Col>
            </>
          }

          { isAdmin &&
            <>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Save Estimate</Button>
                </Form.Item>
              </Col>
            </>
          }
        </Row>
      </Form>
    </>
  )
}
