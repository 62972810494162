import React, { useContext, useEffect, useState } from 'react'
import { Link, Routes, Route, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import {
  BookOutlined,
  FormOutlined,
  GoldOutlined,
  ToolOutlined,
  UserOutlined
} from '@ant-design/icons'

import preval from 'preval.macro'

import Resources from './resources'
import CreateEstimate from './estimates/create'
import ViewEstimates from './estimates'
import StorageCalculator from './tools/StorageCalculator'
import GrainCalculator from './tools/GrainCalculator'
import SnowWindLoad from './tools/SnowWindLoad'
import Users from './users'
import PartsAndMaterials from './PartsAndMaterials'
import EditUser from './users/edit.js'

import { userContext } from '../context/User.js'

const { Header, Content, Sider } = Layout

const buildDateTimeStamp = preval`module.exports = new Date().toLocaleString()`

export default function Dashboard () {
  const [ sideNavMenuKey, setSideNavMenuKey ] = useState()
  const [ topNavMenuKey, setTopNavMenuKey ] = useState()
  const user = useContext(userContext)
  const location = useLocation()

  const setSelectedMenuKeys = () => {
    const path = location.pathname.replace(/^\/+|\/+$/g, '').split('/')

    let newSideNavMenuKey = null
    let newTopNavMenuKey = null

    switch(path[0]) {
      case 'users':
        newSideNavMenuKey = 'manage-users'

        if (path[1] === user.id) {
          newTopNavMenuKey = 'account'
        }
        break
      case 'estimates/new':
        newSideNavMenuKey = 'create-estimate'
        break
      case 'estimates':
        switch(path[1]) {
          case 'new':
            newSideNavMenuKey = 'create-estimate'
            break
          default:
            newSideNavMenuKey = 'view-estimates'
        }
        break
      case 'storage-calculator':
        newSideNavMenuKey = 'storage-calculator'
        break
      case 'snow-wind-load':
        newSideNavMenuKey = 'snow-wind-load'
        break
      case 'parts-materials':
        newSideNavMenuKey = 'parts-materials'
        break
      default:
        newSideNavMenuKey = 'resources'
    }

    setSideNavMenuKey(newSideNavMenuKey)
    setTopNavMenuKey(newTopNavMenuKey)
  }

  useEffect(() => {
    setSelectedMenuKeys()
  }, [ location ])

  return (
    <Layout className="dashboard">
      <Header className="dashboard-header">
        <Link to="/" className="brand">
          <img src="/logo-alt.png" alt="AccuSteel" />
        </Link>
        <Menu
          className="dashboard-top-navigation"
          theme="dark"
          mode="horizontal"
          selectedKeys={[ topNavMenuKey ]}
          items={[
            {
              key: 'account',
              label: <Link to={ `/account` }><UserOutlined style={{ verticalAlign: 'middle' }} /></Link>
            },
            {
              key: 'logout',
              label: <Link to="/logout">Log out</Link>
            }
          ]}
        />
      </Header>
      <Layout>
        <Sider className="dashboard-sider">
          <Menu
            className="dashboard-side-navigation"
            mode="inline"
            selectedKeys={[ sideNavMenuKey ]}
            defaultOpenKeys={[
              'estimates-submenu',
              'tools-submenu',
              'users-submenu'
            ]}
            items={
              [
                {
                  key: 'resources',
                  label: <Link to="/">Resources</Link>,
                  icon: <BookOutlined />
                }
              ]
              .concat(user.permissions.some(p => ['view-estimates', 'create-estimates'].includes(p)) ? [
                {
                  key: 'estimates-submenu',
                  label: 'Estimates',
                  icon: <FormOutlined />,
                  children: []
                    .concat(user.permissions.includes('create-estimates') ? [
                      {
                        key: 'create-estimate',
                        label: <Link to="/estimates/new">Create an Estimate</Link>
                      }
                    ] : [])
                    .concat(user.permissions.includes('view-estimates') ? [
                      {
                        key: 'view-estimates',
                        label: <Link to="/estimates">View Estimates</Link>
                      }
                    ] : [])
                }
              ] : [])
              .concat(user.permissions.includes('storage-calculator', 'snow-wind-load') ? [
                {
                  key: 'tools-submenu',
                  label: 'Tools',
                  icon: <ToolOutlined />,
                  children: [
                    {
                      key: 'storage-calculator',
                      label: <Link to="/storage-calculator">Salt Storage Calculator</Link>,
                    },
                    {
                      key: 'grain-calculator',
                      label: <Link to="/grain-calculator">Grain Storage Calculator</Link>,
                    },
                    {
                      key: 'snow-wind-load',
                      label: <Link to="/snow-wind-load">Snow & Wind Load Charts</Link>,
                    }
                  ]
                }
              ] : [])
              .concat(user.permissions.includes('manage-users') ? [
                {
                  key: 'users-submenu',
                  label: 'Users',
                  icon: <UserOutlined />,
                  children: [
                    {
                      key: 'manage-users',
                      label: <Link to="/users">Manage Users</Link>,
                    }
                  ]
                }
              ] : [])
              .concat(user.permissions.includes('manage-parts-materials') ? [
                {
                  key: 'parts-materials',
                  label: <Link to="/parts-materials">Parts &amp; Materials</Link>,
                  icon: <GoldOutlined />
                }
              ] : [])
            }
          />
        </Sider>
        <Layout>
          <Content className="dashboard-content">
            <Routes>
              <Route path='/' element={<Resources />} />
              <Route path='/account' element={ <EditUser /> }></Route>

              { user.permissions.includes('view-estimates') &&
                <Route path='/estimates/*' element={<ViewEstimates />} />
              }

              { user.permissions.includes('create-estimates') &&
                <Route path='/estimates/new/*' element={<CreateEstimate />} />
              }

              { user.permissions.includes('storage-calculator') &&
                <Route path='/storage-calculator' element={<StorageCalculator />} />
              }

              { user.permissions.includes('storage-calculator') &&
                <Route path='/grain-calculator' element={<GrainCalculator />} />
              }

              { user.permissions.includes('snow-wind-load') &&
                <Route path='/snow-wind-load' element={<SnowWindLoad />} />
              }

              { user.permissions.includes('manage-users') &&
                <Route path='/users/*' element={<Users />} />
              }

              { user.permissions.includes('manage-parts-materials') &&
                <Route path='/parts-materials' element={ <PartsAndMaterials /> } />
              }
            </Routes>

            <div id="footer-content">
              { user.permissions.includes('manage-parts-materials') && <span>(Build Date: { buildDateTimeStamp })</span> }
              <span>&copy;{ new Date().getFullYear() } <a href="https://accusteel.com" target="_blank">Accu-Steel</a></span>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
