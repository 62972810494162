export const manufacturedPartCalculations = {
  '41011183': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41030145': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 1)
    }
  },
  '41011182': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050127': values => {
    const foundationConnectionOptions = {
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 2,
      'beams-both-sides': 2,
      'one-side-long-legs-one-side-beams': 1
    }

    const buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int'
    ]

    const foundationConnectionOption = foundationConnectionOptions[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption) {
      return spacingCalculation(values.buildingLength, foundationConnectionOption)
    }
  },
  '41050131': values => {
    const foundationConnectionOptions = {
      'both-sides-long-legs': 2,
      'one-side-long-legs-one-side-standard': 2,
      'one-side-long-legs-one-side-beams': 2
    }

    const buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int'
    ]

    const foundationConnectionOption = foundationConnectionOptions[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption) {
      return spacingCalculation(values.buildingLength, foundationConnectionOption)
    }
  },
  '41011153': values => {
    const foundationConnectionOptions = {
      'beams-both-sides': 2,
      'beams-one-side-one-side-standard': 1,
      'one-side-long-legs-one-side-beams': 1,
    }

    const buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int'
    ]

    const foundationConnectionOption = foundationConnectionOptions[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption) {
      return spacingCalculation(values.buildingLength, foundationConnectionOption)
    }
  },
  '41050585': values => {
    if (values.buildingWidth === '88-0-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050126': values => {
    const buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int',
      '46-0-hp-int',
      '50-2-hp-int',
      '62-2-hp-int',
      '67-0-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 1)
    }
  },
  '41030051': values => {
    const buildingWidthArray = [
      '46-0-int',
      '46-0-hp-int',
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050128': values => {
    const buildingWidthArray = [
      '50-2-int',
      '50-2-hp-int',
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050129': values => {
    const buildingWidthArray = [
      '62-2-int',
      '62-2-hp-int',
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050130': values => {
    const buildingWidthArray = [
      '67-0-int',
      '67-0-hp-int',
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41011093': values => {
    if (parseInt(values.premiumEaves) < 2) {
      let sum = 0

      const foundationConnectionOptions61 = {
        'baseplates-only': 2,
        'beams-one-side-one-side-standard': 2,
        'one-side-long-legs-one-side-standard': 2,
        'beams-both-sides': 2,
        'one-side-long-legs-one-side-beams': 1
      }

      const buildingWidthArray = [
        '46-0-int',
        '50-2-int',
        '62-2-int',
        '67-0-int'
      ]

      const foundationConnectionOptions62 = {
        'both-sides-long-legs': 2,
        'one-side-long-legs-one-side-standard': 2,
        'one-side-long-legs-one-side-beams': 2
      }

      const foundationConnectionOption61 = foundationConnectionOptions61[values.foundationConnection]
      const foundationConnectionOption62 = foundationConnectionOptions62[values.foundationConnection]

      if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption61) {
        sum += spacingCalculation(values.buildingLength, foundationConnectionOption61)
      }

      if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption62) {
        sum += spacingCalculation(values.buildingLength, foundationConnectionOption62)
      }

      return sum / (parseInt(values.premiumEaves) + 1)
    }
  },
  '41030344': values => getPremiumEaves(values),
  '41030371': values => getPremiumEaves(values),
  '41030372': values => getPremiumEaves(values),
  '41030059': values => {
    const foundationConnectionOptions = {
      'beams-both-sides': 2,
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 1
    }
    
    const buildingWidthArray = [
      '74-8-int',
      '88-0-int'
    ]

    const foundationConnectionOption = foundationConnectionOptions[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption) {
      return spacingCalculation(values.buildingLength, foundationConnectionOption)
    }
  },
  '41011137': values => {
    if (values.buildingWidth === '75-11-hp-int') {
      const foundationConnectionOptions = {
        'both-sides-long-legs': 2,
        'one-side-long-legs-one-side-standard': 1,
        'one-side-long-legs-one-side-beams': 1
      }

      const foundationConnectionOption = foundationConnectionOptions[values.foundationConnection]

      if (foundationConnectionOption) {
        return spacingCalculation(values.buildingLength, foundationConnectionOption)
      }
    }
  },
  '41010506': values => {
    const buildingWidthArray = [
      '74-8-int',
      '75-11-hp-int',
      '88-0-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 3)
    }
  },
  '41010507': values => {
    const buildingWidthArray = [
      '74-8-int',
      '75-11-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050399': values => {
    if (values.buildingWidth === '88-0-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41030400': values => {
    if (parseInt(values.premiumEaves) < 2) {
      let sum = 0

      const foundationConnectionOptions74 = {
        'beams-both-sides': 2,
        'baseplates-only': 2,
        'beams-one-side-one-side-standard': 2,
        'one-side-long-legs-one-side-standard': 1
      }

      const buildingWidthArray = [
        '74-8-int',
        '88-0-int'
      ]

      const foundationConnectionOption74 = foundationConnectionOptions74[values.foundationConnection]

      if (buildingWidthArray.includes(values.buildingWidth) && foundationConnectionOption74) {
        sum += spacingCalculation(values.buildingLength, foundationConnectionOption74)
      }

      if (values.buildingWidth === '74-8-int') {
        const foundationConnectionOptions75 = {
          'both-sides-long-legs': 2,
          'one-side-long-legs-one-side-standard': 1,
          'one-side-long-legs-one-side-beams': 1
        }

        const foundationConnectionOption75 = foundationConnectionOptions75[values.foundationConnection]

        if (foundationConnectionOption75) {
          sum += spacingCalculation(values.buildingLength, foundationConnectionOption75)
        }
      }

      return sum / (parseInt(values.premiumEaves) + 1)
    }
  },
  '41050142': values => {
    if (values.buildingWidth === '96-9-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050143': values => {
    if (values.buildingWidth === '98-1-hp-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41030052': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41050144': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 1)
    }
  },
  '41050145': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41030057': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41010187': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 2, 0)
    }
  },
  '41050139': values => {
    const buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int',
      '74-8-int',
      '88-0-int',
      '46-0-hp-int',
      '50-2-hp-int',
      '62-2-hp-int',
      '67-0-hp-int',
      '75-11-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2, 0)
    }
  },
  '41010020': values => {
    if (values.buildingWidth === '38-0-int') {
      if (values.buildingLength > 192) {
        return Math.ceil(spacingCalculation(values.buildingLength, 4, 0) + (((values.buildingLength / 192) * 4) + 4))
      }

      return Math.ceil(spacingCalculation(values.buildingLength, 4, 0) + 8)
    } else {
      return 4
    }
  },
  '41010515': values => {
    const buildingWidth = {
      '46-0-int': 5,
      '50-2-int': 7,
      '62-2-int': 7,
      '67-0-int': 7,
      '74-8-int': 9,
      '88-0-int': 11,
      '46-0-hp-int': 7,
      '50-2-hp-int': 7,
      '62-2-hp-int': 9,
      '67-0-hp-int': 9,
      '75-11-hp-int': 11
    }

    const condition = buildingWidth[values.buildingWidth]

    if (condition) {
      return Math.ceil(spacingCalculation(values.buildingLength, condition, 0))
    }
  },
  '41010185': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 4)
    }
  },
  '41010525': values => {
    const buildingWidth = {
      '46-0-int': 4,
      '50-2-int': 6,
      '62-2-int': 6,
      '67-0-int': 6,
      '74-8-int': 8,
      '88-0-int': 10,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 6,
      '67-0-hp-int': 6,
      '75-11-hp-int': 8
    }

    const condition = buildingWidth[values.buildingWidth]

    if (condition) {
      return Math.ceil(spacingCalculation(values.buildingLength, condition))
    }
  },
  '41011035': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return Math.ceil(spacingCalculation(values.buildingLength, 11, 0))
    }
  },
  '41030058': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return 4
    }
  },
  '41011044': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return Math.ceil(spacingCalculation(values.buildingLength, 11))
    }
  },
  '41010941': values => {
    const conditions = {
      group1: {
        '46-0-int': 4,
        '50-2-int': 4
      },
      group2: {
        '46-0-hp-int': 4,
        '50-2-hp-int': 4,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      },
      group3: {
        '74-8-int': 6
      },
      group4: {
        '88-0-int': 8
      },
      group5: {
        '75-11-hp-int': 6
      }
    }

    return calculateRafterInsert(values, conditions)
  },
  '41010529': values => {
    const conditions = {
      group1: {
        '46-0-int': 4
      },
      group2: {
        '46-0-hp-int': 4,
        '50-2-hp-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      },
      group3: {
        '74-8-int': 4
      },
      group4: {
        '88-0-int': 6
      },
      group5: {
        '75-11-hp-int': 6
      }
    }

    return calculateRafterInsert(values, conditions)
  },
  '41011138': values => {
    const conditions = {
      group1: {
        '62-2-int': 4,
        '67-0-int': 4
      },
      group2: {
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      }
    }

    return calculateRafterInsert(values, conditions)
  },
  '41011139': values => {
    const conditions = {
      group1: {
        '50-2-int': 4,
        '62-2-int': 4,
        '67-0-int': 4,
      },
      group2: {
        '50-2-hp-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      }
    }

    return calculateRafterInsert(values, conditions)
  },
  '41010024': values => {
    if (values.buildingWidth === '38-0-int') {
      return spacingCalculation(values.buildingLength, 4)
    }
  },
  '41045000': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 4)
    }
  },
  '41055000': values => {
    const buildingWidth = {
      '96-9-int': 6,
      '98-1-hp-int': 8
    }

    const condition = buildingWidth[values.buildingWidth]

    if (condition) {
      return spacingCalculation(values.buildingLength, condition)
    }
  },
  '41030007': values => {
    if (values.buildingWidth === '96-9-int') {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '41010504': values => {
    const legs = getLegs(values)

    if ((values['wall-brackets'] !== '8') && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      let basePlates = (legs - 4)

      let singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        basePlates = (basePlates / 2)
      }

      return Math.ceil(basePlates)
    }
  },
  '41010793': values => {
    if (values.buildingWidth !== '38-0-int' && getLegs(values)) {
      const foundationConnections = {
        'baseplates-only': 4,
        'beams-one-side-one-side-standard': 2,
        'one-side-long-legs-one-side-standard': 4,
        'beams-both-sides': 0,
        'one-side-long-legs-one-side-beams': 2
      }

      const condition = foundationConnections[values.foundationConnection]

      return condition
    }
  },
  '41030047': values => {
    const legs = getLegs(values)

    if ((values['wall-brackets'] === '8') && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      const basePlates = legs

      const singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        return Math.ceil((basePlates - 2) / 2)
      }

      return Math.ceil(basePlates - 4)
    }
  },
  '41030032': values => calculateWallBracketTPanel(values),
  '41030009': values => {
    if (values.buildingWidth === '96-9-int') {
      return spacingCalculation(values.buildingLength, 2) - 4
    }

    if (values.buildingWidth === '98-1-hp-int') {
      return spacingCalculation(values.buildingLength, 2) - 4
    }
  },
  '41030010': values => {
    if (values.buildingWidth === '96-9-int') {
      return 4
    }

    if (values.buildingWidth === '98-1-hp-int') {
      return 8
    }
  },
  '41010295': values => {
    if (values.buildingWidth !== '96-9-int' && values.buildingWidth !== '98-1-hp-int') {
      return spacingCalculation(values.buildingLength, 2, 0)
    }
  },
  '41090227': values => {
    if (values.buildingWidth === '96-9-int' || values.buildingWidth === '98-1-hp-int') {
      return spacingCalculation(values.buildingLength, 2, 0)
    }
  },
  '41010027': values => {
    if (values.doorEndwallKit > 0) {
      const conditions = {
        '38-0-int': 2,
        '46-0-int': 2,
        '50-2-int': 2,
        '62-2-int': 2,
        '67-0-int': 2,
        '74-8-int': 3,
        '88-0-int': 3,
        '46-0-hp-int': 2,
        '50-2-hp-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2,
        '75-11-hp-int': 3
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return condition
      }
    }
  },
  '41050063': values => {
    const conditions = {
      '96-9-int': 4,
      '98-1-hp-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition
    }
  },
  '91215000': values => {
    const conditions = {
      '38-0-int': 2.5,
      '46-0-int': 3,
      '50-2-int': 3,
      '62-2-int': 3.5,
      '67-0-int': 4,
      '74-8-int': 4.5,
      '88-0-int': 5,
      '46-0-hp-int': 3,
      '50-2-hp-int': 3,
      '62-2-hp-int': 3.5,
      '67-0-hp-int': 4,
      '75-11-hp-int': 4.5
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return Math.ceil(spacingCalculation(values.buildingLength, condition))
    }
  },
  '91210000': values => {
    const conditions = {
      '96-9-int': 131,
      '98-1-hp-int': 141
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return Math.ceil(spacingCalculation(values.buildingLength, Math.ceil(condition / 23.5)))
    }
  },
  '41010294': values => spacingCalculation(values.buildingLength, 2),
  '41010639': values => {
    const buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      return spacingCalculation(values.buildingLength, 2)
    }
  },
  '64435000': values => (values.mainCoversColor === 'white') ? calculateMainCover(values) : 0,
  '64120000': values => (values.mainCoversColor === 'tan') ? calculateMainCover(values) : 0,
  '64130000': values => (values.mainCoversColor === 'green') ? calculateMainCover(values) : 0,
  '64150000': values => (values.mainCoversColor === 'red') ? calculateMainCover(values) : 0,
  '64140000': values => (values.mainCoversColor === 'blue') ? calculateMainCover(values) : 0,
  '23560000': values => calculateThreadedRod(values),
  '41090068': values => {
    const conditions = {
      '46-0-int': 8,
      '50-2-int': 8,
      '62-2-int': 4,
      '67-0-int': 4,
      '46-0-hp-int': 8,
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '43775000': values => {
    const conditions = {
      '46-0-int': 8,
      '50-2-int': 8,
      '74-8-int': 4,
      '88-0-int': 6,
      '96-9-int': 4,
      '46-0-hp-int': 4,
      '50-2-hp-int': 12,
      '62-2-hp-int': 8,
      '67-0-hp-int': 4,
      '75-11-hp-int': 4,
      '98-1-hp-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41010550': values => {
    const conditions = {
      '62-2-int': 4,
      '96-9-int': 4,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4,
      '98-1-hp-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41010913': values => {
    const conditions = {
      '62-2-int': 4,
      '67-0-int': 8,
      '74-8-int': 8,
      '88-0-int': 8,
      '96-9-int': 8,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4,
      '75-11-hp-int': 8,
      '98-1-hp-int': 8
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41175000': values => {
    const conditions = {
      '38-0-int': 6
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41010801': values => {
    const conditions = {
      '38-0-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41010939': values => {
    const conditions = {
      '46-0-int': 10,
      '50-2-int': 10,
      '62-2-int': 10,
      '67-0-int': 10,
      '74-8-int': 10,
      '88-0-int': 12,
      '46-0-hp-int': 10,
      '50-2-hp-int': 10,
      '62-2-hp-int': 10,
      '67-0-hp-int': 10,
      '75-11-hp-int': 10
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '41010434': values => {
    const conditions = {
      '96-9-int': 14,
      '98-1-hp-int': 14
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return calculateCableAssembly(condition, values.buildingLength)
    }
  },
  '31105000': values => 4 + (4 * Math.ceil(values.buildingLength / 100)),
  '41010297': values => {
    const legs = getLegs(values)

    if (values['barrier-backing-plate'] && values['5-8ths-threaded-rods'] && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      let qty = 0

      if (values['wall-brackets'] === '8') {
        const basePlates = legs
        const singleSideBeams = [
          'beams-one-side-one-side-standard',
          'one-side-long-legs-one-side-beams'
        ]

        if (singleSideBeams.includes(values.foundationConnection)) {
          return (Math.ceil((basePlates - 2) / 2) * 3) * (2 / 3)
        }

        return (Math.ceil(basePlates - 4) * 3) * (2 / 3)
      }

      if (values['wall-brackets'] === '4.5') {
        let basePlates = (legs - 4)
        const singleSideBeams = [
          'beams-one-side-one-side-standard',
          'one-side-long-legs-one-side-beams'
        ]

        if (singleSideBeams.includes(values.foundationConnection)) {
          basePlates = (basePlates / 2)
        }

        qty += Math.ceil(basePlates)

        if (values.buildingWidth === '96-9-int' || values.buildingWidth === '98-1-hp-int') {
          qty += spacingCalculation(values.buildingLength, 2) - 4
        }

        return (qty * 3) * (2 / 3)
      }
    }
  },
  '41090182': values => {
    const conditions = {
      '96-9-int': 2,
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '41090183': values => {
    const conditions = {
      '96-9-int': 2,
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '41090192': values => {
    const conditions = {
      '98-1-hp-int': 2
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '41090193': values => {
    const conditions = {
      '98-1-hp-int': 2
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '41010357': values => {
    if (values.doorEndwallKit) {
      if (values.buildingWidth === '96-9-int') {
        if (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) {
          return 6
        } else {
          return 5
        }
      }

      if (values.buildingWidth === '98-1-hp-int') {
        if (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) {
          return 6 * values.doorEndwallKit
        } else {
          return 5 * values.doorEndwallKit
        }
      }
    }
  },
  '41011135': values => {
    if (values.doorEndwallKit) {
      if (values.buildingWidth === '98-1-hp-int') {
        return 2
      }

      if (values.buildingWidth === '96-9-int' && !values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) {
        return 2 * values.doorEndwallKit
      }
    }
  },
  '41030041': values => {
    if (values.doorEndwallKit) {
      if (values.buildingWidth === '98-1-hp-int' || (values.buildingWidth === '96-9-int' && values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'])) {
        return 2 * values.doorEndwallKit
      }
    }
  },
  '41011134': values => {
    if (values.doorEndwallKit && values.buildingWidth === '98-1-hp-int') {
      return 2 * values.doorEndwallKit
    }
  },
  '41010359': values => {
    const conditions = {
      '96-9-int': 2,
      '98-1-hp-int': 6
    }

    const condition = conditions[values.buildingWidth]

    if (condition && values.doorEndwallKit) {
      return values.doorEndwallKit * condition
    }
  },
  '31010135': values => calculateEndwallColumn(values),
  '31010142L': values => calculateEndwallColumn(values),
  '31010142R': values => calculateEndwallColumn(values),
  '31995032': values => {
    const conditions = {
      '96-9-int': [14, 12],
      '98-1-hp-int': [18, 16]
    }

    const condition = conditions[values.buildingWidth]

    if (values.doorEndwallKit && condition) {
      if (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) {
        return values.doorEndwallKit * condition[0]
      }

      return values.doorEndwallKit * condition[1]
    }
  },
  '31190000': values => {
    if (values.doorEndwallKit) {
      const conditions = {
        '38-0-int': 4,
        '46-0-int': 4,
        '50-2-int': 4,
        '62-2-int': 6,
        '67-0-int': 6,
        '88-0-int': 8,
        '46-0-hp-int': 4,
        '50-2-hp-int': 4,
        '62-2-hp-int': 6,
        '67-0-hp-int': 6,
        '75-11-hp-int': 8
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return values.doorEndwallKit * condition
      }

      if (values.buildingWidth === '74-8-int') {
        if (getTwoWayDoors(values)) {
          return values.doorEndwallKit * 6
        } else {
          return values.doorEndwallKit * 8
        }
      }
    }
  },
  '41325000': values => {
    if (getVentPurlins(values)) {
      return parseInt(getOneWayDoors(values)) + (getVentPurlins(values) - 4)
    }
  },
  '41010161': values => {
    if (values.doorEndwallKit) {
      const conditions = {
        group1: {
          '38-0-int': 2,
          '46-0-int': 2,
          '50-2-int': 2,
          '62-2-int': 4,
          '67-0-int': 6,
          '88-0-int': 8,
          '46-0-hp-int': 2,
          '50-2-hp-int': 2,
          '62-2-hp-int': 4,
          '67-0-hp-int': 4,
          '75-11-hp-int': 8
        },
        group2: {
          '74-8-int': [6, 8],
          '98-1-hp-int': [8, 4],
          '96-9-int': [8, 4]
        }
      }

      let condition = conditions.group1[values.buildingWidth]

      if (condition) {
        return values.doorEndwallKit * condition
      }

      condition = conditions.group2[values.buildingWidth]

      if (condition) {
        if (getTwoWayDoors(values)) {
          return values.doorEndwallKit * condition[0]
        }

        return values.doorEndwallKit * condition[1]
      }
    }
  },
  '41010162': values => calculateEndwallBasePlate(values),
  '41010163': values => {
    if (values.doorEndwallKit) {
      const conditions = {
        group1: {
          '38-0-int': 3,
          '50-2-int': 2,
          '50-2-hp-int': 2
        },
        group2: {
          '62-2-int': [2, 3],
          '67-0-int': [4, 2],
          '74-8-int': [2, 4],
          '88-0-int': [8, 8],
          '96-9-int': [3, 1],
          '46-0-hp-int': [2, 0],
          '62-2-hp-int': [4, 3],
          '67-0-hp-int': [4, 2],
          '75-11-hp-int': [4, 2],
          '98-1-hp-int': [3, 1]
        }
      }

      let condition = conditions.group1[values.buildingWidth]

      if (condition) {
        return values.doorEndwallKit * condition
      }

      condition = conditions.group2[values.buildingWidth]

      if (condition) {
        if (getTwoWayDoors(values)) {
          return values.doorEndwallKit * condition[0]
        }

        return values.doorEndwallKit * condition[1]
      }
    }
  },
  '41010167': values => {
    let qty = 0

    if (values.doorEndwallKit) {
      const conditions = {
        group1: {
          '38-0-int': 3,
          '46-0-int': 3,
          '50-2-int': 2,
          '50-2-hp-int': 2,
          '67-0-int': 2
        },
        group2: {
          '62-2-int': [2, 0],
          '74-8-int': [2, 1],
          '96-9-int': [0, 2],
          '46-0-hp-int': [2, 3],
          '62-2-hp-int': [2, 0],
          '67-0-hp-int': [1, 3],
          '75-11-hp-int': [2, 4],
          '98-1-hp-int': [0, 2]
        }
      }

      let condition = conditions.group1[values.buildingWidth]

      if (condition) {
        qty += values.doorEndwallKit * condition
      }

      condition = conditions.group2[values.buildingWidth]

      if (condition) {
        if (getTwoWayDoors(values)) {
          qty += values.doorEndwallKit * condition[0]
        }

        qty += values.doorEndwallKit * condition[1]
      }
    }

    return qty + (getDoorKits24(values) * 2)
  },
  '41010623': values => {
    if (values.buildingWidth === '88-0-int') return 2

    if (values.doorEndwallKit) {
      const conditions = {
        '62-2-int': 2,
        '67-0-int': 2,
        '74-8-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2,
        '75-11-hp-int': 2
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return values.doorEndwallKit * condition
      }
    }
  },
  '41010626': values => {
    if (values.doorEndwallKit) {
      const conditions = {
        '62-2-int': 2,
        '67-0-int': 2,
        '74-8-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return values.doorEndwallKit * condition
      }
    }
  },
  '41010625': values => {
    const conditions = {
      '88-0-int': 2,
      '75-11-hp-int': 2
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition
    }
  },
  '41010627': values => {
    if (values.buildingWidth === '88-0-int') return 2 * 2

    if (values.doorEndwallKit) {
      const conditions = {
        '62-2-int': 2,
        '67-0-int': 2,
        '74-8-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2,
        '75-11-hp-int': 2
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return 2 * (values.doorEndwallKit * condition)
      }
    }
  },
  '41010164': values => {
    return (
      ((values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) * 8) +
      ((values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) * 7) +
      ((values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0) * 8) +
      ((values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) * 7) +
      ((values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0) * 6)
    )
  },
  '31050056': values => {
    return (
      ((values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) * 3) +
      ((values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0) * 3) +
      ((values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0) * 2)
    )
  },
  '31050057': values => {
    return (
      ((values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) * 2) +
      ((values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) * 2)
    )
  },
  '42115000': values => getOneWayDoors(values) + (getTwoWayDoors(values) * 2),
  '31010006': values => getDoorKits(values) * 3,
  'EWC16C38SWW': values => calculateEndwallCovers(values, 'EWC16C38SWW'),
  'EWC16C46SWW': values => calculateEndwallCovers(values, 'EWC16C46SWW'),
  'EWC16C50SWW': values => calculateEndwallCovers(values, 'EWC16C50SWW'),
  'EWC16C62SWW': values => calculateEndwallCovers(values, 'EWC16C62SWW'),
  'EWC16C67SWW': values => calculateEndwallCovers(values, 'EWC16C67SWW'),
  'EWC16C74SWW': values => calculateEndwallCovers(values, 'EWC16C74SWW'),
  'EWC16C88SWW': values => calculateEndwallCovers(values, 'EWC16C88SWW'),
  'EWC16C96SWW': values => calculateEndwallCovers(values, 'EWC16C96SWW'),
  'EWC16C46HPWW': values => calculateEndwallCovers(values, 'EWC16C46HPWW'),
  'EWC16C50HPWW': values => calculateEndwallCovers(values, 'EWC16C50HPWW'),
  'EWC16C62HPWW': values => calculateEndwallCovers(values, 'EWC16C62HPWW'),
  'EWC16C67HPWW': values => calculateEndwallCovers(values, 'EWC16C67HPWW'),
  'EWC16C75HPWW': values => calculateEndwallCovers(values, 'EWC16C75HPWW'),
  'EWC16C98HPWW': values => calculateEndwallCovers(values, 'EWC16C98HPWW'),
  'EWC16C38STW': values => calculateEndwallCovers(values, 'EWC16C38STW'),
  'EWC16C46STW': values => calculateEndwallCovers(values, 'EWC16C46STW'),
  'EWC16C50STW': values => calculateEndwallCovers(values, 'EWC16C50STW'),
  'EWC16C62STW': values => calculateEndwallCovers(values, 'EWC16C62STW'),
  'EWC16C67STW': values => calculateEndwallCovers(values, 'EWC16C67STW'),
  'EWC16C74STW': values => calculateEndwallCovers(values, 'EWC16C74STW'),
  'EWC16C88STW': values => calculateEndwallCovers(values, 'EWC16C88STW'),
  'EWC16C96STW': values => calculateEndwallCovers(values, 'EWC16C96STW'),
  'EWC16C46HPTW': values => calculateEndwallCovers(values, 'EWC16C46HPTW'),
  'EWC16C50HPTW': values => calculateEndwallCovers(values, 'EWC16C50HPTW'),
  'EWC16C62HPTW': values => calculateEndwallCovers(values, 'EWC16C62HPTW'),
  'EWC16C67HPTW': values => calculateEndwallCovers(values, 'EWC16C67HPTW'),
  'EWC16C75HPTW': values => calculateEndwallCovers(values, 'EWC16C75HPTW'),
  'EWC16C98HPTW': values => calculateEndwallCovers(values, 'EWC16C98HPTW'),
  'EWC16C38SGW': values => calculateEndwallCovers(values, 'EWC16C38SGW'),
  'EWC16C46SGW': values => calculateEndwallCovers(values, 'EWC16C46SGW'),
  'EWC16C50SGW': values => calculateEndwallCovers(values, 'EWC16C50SGW'),
  'EWC16C62SGW': values => calculateEndwallCovers(values, 'EWC16C62SGW'),
  'EWC16C67SGW': values => calculateEndwallCovers(values, 'EWC16C67SGW'),
  'EWC16C74SGW': values => calculateEndwallCovers(values, 'EWC16C74SGW'),
  'EWC16C88SGW': values => calculateEndwallCovers(values, 'EWC16C88SGW'),
  'EWC16C96SGW': values => calculateEndwallCovers(values, 'EWC16C96SGW'),
  'EWC16C46HPGW': values => calculateEndwallCovers(values, 'EWC16C46HPGW'),
  'EWC16C50HPGW': values => calculateEndwallCovers(values, 'EWC16C50HPGW'),
  'EWC16C62HPGW': values => calculateEndwallCovers(values, 'EWC16C62HPGW'),
  'EWC16C67HPGW': values => calculateEndwallCovers(values, 'EWC16C67HPGW'),
  'EWC16C75HPGW': values => calculateEndwallCovers(values, 'EWC16C75HPGW'),
  'EWC16C98HPGW': values => calculateEndwallCovers(values, 'EWC16C98HPGW'),
  'EWC16C38SRW': values => calculateEndwallCovers(values, 'EWC16C38SRW'),
  'EWC16C46SRW': values => calculateEndwallCovers(values, 'EWC16C46SRW'),
  'EWC16C50SRW': values => calculateEndwallCovers(values, 'EWC16C50SRW'),
  'EWC16C62SRW': values => calculateEndwallCovers(values, 'EWC16C62SRW'),
  'EWC16C67SRW': values => calculateEndwallCovers(values, 'EWC16C67SRW'),
  'EWC16C74SRW': values => calculateEndwallCovers(values, 'EWC16C74SRW'),
  'EWC16C88SRW': values => calculateEndwallCovers(values, 'EWC16C88SRW'),
  'EWC16C96SRW': values => calculateEndwallCovers(values, 'EWC16C96SRW'),
  'EWC16C46HPRW': values => calculateEndwallCovers(values, 'EWC16C46HPRW'),
  'EWC16C50HPRW': values => calculateEndwallCovers(values, 'EWC16C50HPRW'),
  'EWC16C62HPRW': values => calculateEndwallCovers(values, 'EWC16C62HPRW'),
  'EWC16C67HPRW': values => calculateEndwallCovers(values, 'EWC16C67HPRW'),
  'EWC16C74HPRW': values => calculateEndwallCovers(values, 'EWC16C74HPRW'),
  'EWC16C98HPRW': values => calculateEndwallCovers(values, 'EWC16C98HPRW'),
  'EWC16C38SBW': values => calculateEndwallCovers(values, 'EWC16C38SBW'),
  'EWC16C46SBW': values => calculateEndwallCovers(values, 'EWC16C46SBW'),
  'EWC16C50SBW': values => calculateEndwallCovers(values, 'EWC16C50SBW'),
  'EWC16C62SBW': values => calculateEndwallCovers(values, 'EWC16C62SBW'),
  'EWC16C67SBW': values => calculateEndwallCovers(values, 'EWC16C67SBW'),
  'EWC16C74SBW': values => calculateEndwallCovers(values, 'EWC16C74SBW'),
  'EWC16C88SBW': values => calculateEndwallCovers(values, 'EWC16C88SBW'),
  'EWC16C96SBW': values => calculateEndwallCovers(values, 'EWC16C96SBW'),
  'EWC16C46HPBW': values => calculateEndwallCovers(values, 'EWC16C46HPBW'),
  'EWC16C50HPBW': values => calculateEndwallCovers(values, 'EWC16C50HPBW'),
  'EWC16C62HPBW': values => calculateEndwallCovers(values, 'EWC16C62HPBW'),
  'EWC16C67HPBW': values => calculateEndwallCovers(values, 'EWC16C67HPBW'),
  'EWC16C75HPBW': values => calculateEndwallCovers(values, 'EWC16C75HPBW'),
  'EWC16C98HPBW': values => calculateEndwallCovers(values, 'EWC16C98HPBW'),
  'EWC20C46SWW': values => calculateEndwallCovers(values, 'EWC20C46SWW'),
  'EWC20C50SWW': values => calculateEndwallCovers(values, 'EWC20C50SWW'),
  'EWC20C62SWW': values => calculateEndwallCovers(values, 'EWC20C62SWW'),
  'EWC20C67SWW': values => calculateEndwallCovers(values, 'EWC20C67SWW'),
  'EWC20C74SWW': values => calculateEndwallCovers(values, 'EWC20C74SWW'),
  'EWC20C88SWW': values => calculateEndwallCovers(values, 'EWC20C88SWW'),
  'EWC20C96SWW': values => calculateEndwallCovers(values, 'EWC20C96SWW'),
  'EWC20C46HPWW': values => calculateEndwallCovers(values, 'EWC20C46HPWW'),
  'EWC20C50HPWW': values => calculateEndwallCovers(values, 'EWC20C50HPWW'),
  'EWC20C62HPWW': values => calculateEndwallCovers(values, 'EWC20C62HPWW'),
  'EWC20C67HPWW': values => calculateEndwallCovers(values, 'EWC20C67HPWW'),
  'EWC20C75HPWW': values => calculateEndwallCovers(values, 'EWC20C75HPWW'),
  'EWC20C98HPWW': values => calculateEndwallCovers(values, 'EWC20C98HPWW'),
  'EWC20C46STW': values => calculateEndwallCovers(values, 'EWC20C46STW'),
  'EWC20C50STW': values => calculateEndwallCovers(values, 'EWC20C50STW'),
  'EWC20C62STW': values => calculateEndwallCovers(values, 'EWC20C62STW'),
  'EWC20C67STW': values => calculateEndwallCovers(values, 'EWC20C67STW'),
  'EWC20C74STW': values => calculateEndwallCovers(values, 'EWC20C74STW'),
  'EWC20C88STW': values => calculateEndwallCovers(values, 'EWC20C88STW'),
  'EWC20C96STW': values => calculateEndwallCovers(values, 'EWC20C96STW'),
  'EWC20C46HPTW': values => calculateEndwallCovers(values, 'EWC20C46HPTW'),
  'EWC20C50HPTW': values => calculateEndwallCovers(values, 'EWC20C50HPTW'),
  'EWC20C62HPTW': values => calculateEndwallCovers(values, 'EWC20C62HPTW'),
  'EWC20C67HPTW': values => calculateEndwallCovers(values, 'EWC20C67HPTW'),
  'EWC20C75HPTW': values => calculateEndwallCovers(values, 'EWC20C75HPTW'),
  'EWC20C98HPTW': values => calculateEndwallCovers(values, 'EWC20C98HPTW'),
  'EWC20C46SGW': values => calculateEndwallCovers(values, 'EWC20C46SGW'),
  'EWC20C50SGW': values => calculateEndwallCovers(values, 'EWC20C50SGW'),
  'EWC20C62SGW': values => calculateEndwallCovers(values, 'EWC20C62SGW'),
  'EWC20C67SGW': values => calculateEndwallCovers(values, 'EWC20C67SGW'),
  'EWC20C74SGW': values => calculateEndwallCovers(values, 'EWC20C74SGW'),
  'EWC20C88SGW': values => calculateEndwallCovers(values, 'EWC20C88SGW'),
  'EWC20C96SGW': values => calculateEndwallCovers(values, 'EWC20C96SGW'),
  'EWC20C46HPGW': values => calculateEndwallCovers(values, 'EWC20C46HPGW'),
  'EWC20C50HPGW': values => calculateEndwallCovers(values, 'EWC20C50HPGW'),
  'EWC20C62HPGW': values => calculateEndwallCovers(values, 'EWC20C62HPGW'),
  'EWC20C67HPGW': values => calculateEndwallCovers(values, 'EWC20C67HPGW'),
  'EWC20C75HPGW': values => calculateEndwallCovers(values, 'EWC20C75HPGW'),
  'EWC20C98HPGW': values => calculateEndwallCovers(values, 'EWC20C98HPGW'),
  'EWC20C46SRW': values => calculateEndwallCovers(values, 'EWC20C46SRW'),
  'EWC20C50SRW': values => calculateEndwallCovers(values, 'EWC20C50SRW'),
  'EWC20C62SRW': values => calculateEndwallCovers(values, 'EWC20C62SRW'),
  'EWC20C67SRW': values => calculateEndwallCovers(values, 'EWC20C67SRW'),
  'EWC20C74SRW': values => calculateEndwallCovers(values, 'EWC20C74SRW'),
  'EWC20C88SRW': values => calculateEndwallCovers(values, 'EWC20C88SRW'),
  'EWC20C96SRW': values => calculateEndwallCovers(values, 'EWC20C96SRW'),
  'EWC20C46HPRW': values => calculateEndwallCovers(values, 'EWC20C46HPRW'),
  'EWC20C50HPRW': values => calculateEndwallCovers(values, 'EWC20C50HPRW'),
  'EWC20C62HPRW': values => calculateEndwallCovers(values, 'EWC20C62HPRW'),
  'EWC20C67HPRW': values => calculateEndwallCovers(values, 'EWC20C67HPRW'),
  'EWC20C74HPRW': values => calculateEndwallCovers(values, 'EWC20C74HPRW'),
  'EWC20C98HPRW': values => calculateEndwallCovers(values, 'EWC20C98HPRW'),
  'EWC20C46SBW': values => calculateEndwallCovers(values, 'EWC20C46SBW'),
  'EWC20C50SBW': values => calculateEndwallCovers(values, 'EWC20C50SBW'),
  'EWC20C62SBW': values => calculateEndwallCovers(values, 'EWC20C62SBW'),
  'EWC20C67SBW': values => calculateEndwallCovers(values, 'EWC20C67SBW'),
  'EWC20C74SBW': values => calculateEndwallCovers(values, 'EWC20C74SBW'),
  'EWC20C88SBW': values => calculateEndwallCovers(values, 'EWC20C88SBW'),
  'EWC20C96SBW': values => calculateEndwallCovers(values, 'EWC20C96SBW'),
  'EWC20C46HPBW': values => calculateEndwallCovers(values, 'EWC20C46HPBW'),
  'EWC20C50HPBW': values => calculateEndwallCovers(values, 'EWC20C50HPBW'),
  'EWC20C62HPBW': values => calculateEndwallCovers(values, 'EWC20C62HPBW'),
  'EWC20C67HPBW': values => calculateEndwallCovers(values, 'EWC20C67HPBW'),
  'EWC20C75HPBW': values => calculateEndwallCovers(values, 'EWC20C75HPBW'),
  'EWC20C98HPBW': values => calculateEndwallCovers(values, 'EWC20C98HPBW'),
  'CDC16-0-18': values => getDoorKits16(values),
  'CDC19-9-22': values => getDoorKits20(values),
  'CDC19-9-26': values => getDoorKits24(values),
  '36235001': values => getWinches(values, 'right'),
  '36240001': values => getWinches(values, 'left'),
  '31540000': values => 6 * getWinches(values),
  '27120000': values => getCableAssembly(values) + 1,
  '27035000': values => (getCableAssembly(values) * 3) + 1,
  '23570000': values => getStructuralBolts(values),
  '25115000': values => getStructuralBolts(values),
  '25095000': values => getStructuralBolts(values) * 2,
  '23010012': values => getStructuralBolts(values),
  '23050000': values => getStructuralBolts334(values),
  '23085000': values => getStructuralBolts335(values),
  '23110000': values => getStructuralBolts337(values),
  '23105000': values => getStructuralBolts338(values),
  '23130000': values => getStructuralBolts339(values),
  '23120000': values => getStructuralBolts340(values),
  '23140000': values => getStructuralBolts342(values),
  '23650001': values => getStructuralBolts343(values),
  '25015000': values => calculateLockWasher(values),
  '25005000': values => calculateLockWasher(values) * 2,
  '24015000': values => calculateLockWasher(values),
  '23295000': values => getStructuralBolts347(values),
  '25085000': values => getStructuralBolts347(values),
  '25080000': values => getStructuralBolts347(values) * 2,
  '24095000': values => getStructuralBolts347(values),
  '23465003': values => calculateGrade5Bolt(values),
  '25075000': values => (2 * calculateThreadedRod(values)) + ( 2 * getStructuralBolts347(values)) + (2 * calculateGrade5Bolt(values)),
  '25090000': values => (2 * calculateThreadedRod(values)) + getStructuralBolts347(values) + calculateGrade5Bolt(values),
  '24120000': values => (2 * calculateThreadedRod(values)) + getStructuralBolts347(values) + calculateGrade5Bolt(values),
  '23190000': values => 2 * calculateWallBracketTPanel(values),
  '23220000': values => getStructuralBolts356(values),
  '23950008': values => getStructuralBolts357(values),
  '24045000': values => 2 * calculateWallBracketTPanel(values),
  '25035000': values => 2 * calculateWallBracketTPanel(values),
  '25040000': values => 2 * (2 * calculateWallBracketTPanel(values)),
  '27480002': values => calculateTek3Screws(values),
  '31595000': values => {
    const conditions = {
      '38-0-int': 58,
      '46-0-int': 69,
      '50-2-int': 75,
      '62-2-int': 89,
      '67-0-int': 95,
      '74-8-int': 127,
      '88-0-int': 140,
      '96-9-int': 114,
      '46-0-hp-int': 83,
      '50-2-hp-int': 87,
      '62-2-hp-int': 101,
      '67-0-hp-int': 107,
      '75-11-hp-int': 135,
      '98-1-hp-int': 132
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '31580010': values => {
    if (values['fabric-endwall']) {
      const conditions = {
        '38-0-int': 6,
        '46-0-int': 8,
        '50-2-int': 8,
        '62-2-int': 10,
        '67-0-int': 10,
        '74-8-int': 12,
        '88-0-int': 12,
        '96-9-int': 12,
        '46-0-hp-int': 8,
        '50-2-hp-int': 8,
        '62-2-hp-int': 10,
        '67-0-hp-int': 10,
        '75-11-hp-int': 12,
        '98-1-hp-int': 12
      }

      const condition = conditions[values.buildingWidth]

      if (condition) {
        return condition * values.doorEndwallKit
      }
    }
  },
  '31010012': values => calculateRectangularPipeCap(values),
  '41030251': values => {
    const conditions = {
      '96-9-int': 4,
      '98-1-hp-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition - 1
    }
  },
  '27050000': values => Math.ceil((values.buildingLength / 100) + values.doorEndwallKit),
  '31565000': values => calculateHorizontalPulley(values),
  '23240000': values => (3 * calculateBrakeWinch(values)) + (getDoorKits(values) ? 1 : 0),
  '25055000': values => 2 * ((3 * calculateBrakeWinch(values)) + (getDoorKits(values) ? 1 : 0)),
  '24060000': values => (3 * calculateBrakeWinch(values)) + (getDoorKits(values) ? 1 : 0),
  '23285000': values => (3 * calculateHorizontalPulley(values)) + (getDoorKits(values) ? 1 : 0),
  '25070000': values => 2 * ((3 * calculateHorizontalPulley(values)) + (getDoorKits(values) ? 1 : 0)),
  '24085000': values => (3 * calculateHorizontalPulley(values)) + (getDoorKits(values) ? 1 : 0),
  '27025000': values => 5 * calculateBrakeWinch(values),
  '27005000': values => 100 * calculateBrakeWinch(values),
  '27180000': values => calculateFenderWasher(values),
  '27265000': values => calculateBrakeWinch(values),
  '31555000': values => 1,
  '31795000': values => {
    const conditions = {
      '46-0-int': 48,
      '50-2-int': 51,
      '62-2-int': 61,
      '67-0-int': 64,
      '74-8-int': 71,
      '88-0-int': 82,
      '96-9-int': 91,
      '46-0-hp-int': 55,
      '50-2-hp-int': 58,
      '62-2-hp-int': 67,
      '67-0-hp-int': 71,
      '75-11-hp-int': 78,
      '98-1-hp-int': 98
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      return condition * values.doorEndwallKit
    }
  },
  '27090000': values => {
    let qty = 0

    let conditions = {
      '38-0-int': 2.5,
      '46-0-int': 3,
      '50-2-int': 3,
      '62-2-int': 3.5,
      '67-0-int': 4,
      '74-8-int': 4.5,
      '88-0-int': 5,
      '46-0-hp-int': 3,
      '50-2-hp-int': 3,
      '62-2-hp-int': 3.5,
      '67-0-hp-int': 4,
      '75-11-hp-int': 4.5
    }

    let condition = conditions[values.buildingWidth]

    if (condition) {
      qty += Math.ceil(spacingCalculation(values.buildingLength, condition))
    }

    conditions = {
      '96-9-int': 131,
      '98-1-hp-int': 141
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      qty += Math.ceil(spacingCalculation(values.buildingLength, Math.ceil(condition / 23.5)))
    }

    return Math.ceil(qty / 40)
  },
  '27200000': values => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      return 10 + ((4 * calculateEndwallColumn(values)) + (2 * calculateEndwallBasePlate(values)))
    }
  },
  '27465000': values => {
    let qty = 0

    const conditions = {
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 4,
      '67-0-int': 4,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4
    }

    const condition = conditions[values.buildingWidth]

    if (condition && values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      qty += 10
      qty += getWinches(values)
      qty += condition ?? 0
      qty += 4 * getDoorKits(values)
    }

    return qty
  },
  '27215000': (values, parts) => {
    if (values['building-to-foundation-fasteners'] && values.foundationMaterial === 'concrete') {
      const neededParts = (parts['41010504'] + parts['41010793'] + parts['41030047'] + parts['41030009'] + parts['41030010'])
      let results = ((neededParts * 4) + 4)
      if (values['5-8ths-threaded-rods']) {
        results -= (neededParts * 2)
      }
      return results
    }
  },
  '27480001': values => 0,
  '63055007': values => 4,
  '90035000': values => 1,
  '90030000-ITP': values => 0,
  '90030000-ITPC': values => 1,
  '90030000-IPN': values => 1,
  '90030000-1380C': values => values.buildingWidth === '38-0-int' ? 1 : null,
  '90030000-1460': values => 0,
  '90030000-1460HP': values => 0,
  '90030000-1460CB': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-1460CSTD': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-1460CSTDB': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-1460CHP': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-1460CHPSTD': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-1460CHPB': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-1502': values => 0,
  '90030000-1502HP': values => 0,
  '90030000-1502CB': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-1502CSTD': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-1502CSTDB': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-1502CHP': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-1502CHPSTD': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-1502CHPB': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-1622': values => 0,
  '90030000-1622HP': values => 0,
  '90030000-1622CB': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-1622CSTD': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-1622CSTDB': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-1622CHP': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-1622CHPSTD': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-1622CHPB': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-1670': values => 0,
  '90030000-1670HP': values => 0,
  '90030000-1670CB': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-1670CSTD': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-1670CSTDB': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-1670CHP': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-1670CHPSTD': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-1670CHPB': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-1748': values => 0,
  '90030000-17511HP': values => 0,
  '90030000-1748CB': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-1748CSTD': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-1748CSTDB': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-17511CHP': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-17511CHPSTD': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-17511CHPB': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-1969': values => 0,
  '90030000-1969C': values => values.buildingWidth === '96-9-int' ? 1 : null,
  '90030000-1981HP': values => 0,
  '90030000-1981HPC': values => values.buildingWidth === '98-1-hp-int' ? 1 : null,
  '90030000-11144': values => 0,
  '90030000-11158HP': values => 0,
  '90030000-11286': values => 0,
  '90030000-112910HP': values => 0,
  '90030000-1B': values => 0,
  '90030000-1BC': values => (
    (['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int'].includes(values.buildingWidth) && values.foundationConnection === 'baseplates-only') ||
    (['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && values.foundationConnection === 'both-sides-long-legs')
  ) ? 1 : null,
  '90030000-1C': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '88-0-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-1D': values => ['96-9-int', '98-1-hp-int', '114-4-int', '115-8-hp-int', '128-6-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '900-30000-1E460B': values => values.buildingWidth === '46-0-int' ? 1 : null,
  '900-30000-1E502B': values => values.buildingWidth === '50-2-int' ? 1 : null,
  '900-30000-1E622B': values => values.buildingWidth === '62-2-int' ? 1 : null,
  '900-30000-1E670B': values => values.buildingWidth === '67-0-int' ? 1 : null,
  '900-30000-1E748B': values => values.buildingWidth === '74-8-int' ? 1 : null,
  '900-30000-1E380HPB': values => values.buildingWidth === '38-0-hp-int' ? 1 : null,
  '900-30000-1E460HPB': values => values.buildingWidth === '46-0-hp-int' ? 1 : null,
  '900-30000-1E502HPB': values => values.buildingWidth === '50-2-hp-int' ? 1 : null,
  '900-30000-1E622HPB': values => values.buildingWidth === '62-2-hp-int' ? 1 : null,
  '900-30000-1E670HPB': values => values.buildingWidth === '67-0-hp-int' ? 1 : null,
  '900-30000-1E7511HPB': values => values.buildingWidth === '75-11-hp-int' ? 1 : null,
  '900-30000-1F': (values, parts) => parts['41011153'] ? 1 : null,
  '900-30000-02380': values => values.buildingWidth === '38-0-int' ? 1 : null,
  '900-30000-0246-27511': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && (values.foundationConnection === 'baseplates-only' || values.foundationConnection === 'both-sides-long-legs') ? 1 : null,
  '900-30000-0246-27511CB': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '900-30000-0246-27511CB-BP': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && (values.foundationConnection === 'beams-one-side-one-side-standard' || values.foundationConnection === 'one-side-long-legs-one-side-beams') ? 1 : null,
  '900-30000-02969-212910': values => 0,
  '900-30000-02969-212910C': values => ['96-9-int', '98-1-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-3380C': values => values.buildingWidth === '38-0-int' ? 1 : null,
  '90030000-3460': values => 0,
  '90030000-3460HP': values => 0,
  '90030000-3460CB': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-3460CSTD': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-3460CSTDB': values => values.buildingWidth === '46-0-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-3460CHP': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-3460CHPSTD': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-3460CHPB': values => values.buildingWidth === '46-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-3502': values => 0,
  '90030000-3502HP': values => 0,
  '90030000-3502CB': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-3502CSTD': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-3502CSTDB': values => values.buildingWidth === '50-2-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-3502CHP': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-3502CHPSTD': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-3502CHPB': values => values.buildingWidth === '50-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-3622': values => 0,
  '90030000-3622HP': values => 0,
  '90030000-3622CB': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-3622CSTD': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-3622CSTDB': values => values.buildingWidth === '62-2-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-3622CHP': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-3622CHPSTD': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-3622CHPB': values => values.buildingWidth === '62-2-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-3670': values => 0,
  '90030000-3670HP': values => 0,
  '90030000-3670CB': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-3670CSTD': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-3670CSTDB': values => values.buildingWidth === '67-0-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-3670CHP': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-3670CHPSTD': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-3670CHPB': values => values.buildingWidth === '67-0-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-3748': values => 0,
  '90030000-37511HP': values => 0,
  '90030000-3748CB': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-3748CSTD': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'baseplates-only' ? 1 : null,
  '90030000-3748CSTDB': values => values.buildingWidth === '74-8-int' && values.foundationConnection === 'beams-one-side-one-side-standard' ? 1 : null,
  '90030000-37511CHP': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'both-sides-long-legs' ? 1 : null,
  '90030000-37511CHPSTD': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-standard' ? 1 : null,
  '90030000-37511CHPB': values => values.buildingWidth === '75-11-hp-int' && values.foundationConnection === 'one-side-long-legs-one-side-beams' ? 1 : null,
  '90030000-3969': values => 0,
  '90030000-3969C': values => values.buildingWidth === '96-9-int' ? 1 : null,
  '90030000-3981HP': values => 0,
  '90030000-3981HPC': values => values.buildingWidth === '98-1-hp-int' ? 1 : null,
  '90030000-31144': values => 0,
  '90030000-31158HP': values => 0,
  '90030000-31286': values => 0,
  '90030000-312910HP': values => 0,
  '90030000-4A': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && (values.foundationConnection === 'baseplates-only' || values.foundationConnection === 'both-sides-long-legs') ? 1 : null,
  '90030000-4B': values => ['96-9-int', '98-1-hp-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-4C': values => values.buildingWidth === '38-0-int' ? 1 : null,
  '90030000-4BEAMS': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && values.foundationConnection === 'beams-both-sides' ? 1 : null,
  '90030000-4BPB': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && (values.foundationConnection === 'beams-one-side-one-side-standard' || values.foundationConnection === 'one-side-long-legs-one-side-beams') ? 1 : null,
  '90030000-EW-460A': values => 0,
  '90030000-EW-460B': values => 0,
  '90030000-EW-460C': values => values.buildingWidth === '46-0-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-460D': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '46-0-int'),
  '90030000-EW-502A': values => 0,
  '90030000-EW-502B': values => 0,
  '90030000-EW-502C': values => values.buildingWidth === '50-2-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-502D': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '50-2-int'),
  '90030000-EW-622A': values => 0,
  '90030000-EW-622B': values => 0,
  '90030000-EW-622C': values => values.buildingWidth === '62-2-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-622D': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '62-2-int'),
  '90030000-EW-670A': values => 0,
  '90030000-EW-670B': values => 0,
  '90030000-EW-670C': values => values.buildingWidth === '67-0-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-670D': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '67-0-int'),
  '90030000-EW460HPA': values => 0,
  '90030000-EW460HPB': values => 0,
  '90030000-EW-460HPC': values => values.buildingWidth === '46-0-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-460HPD': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '46-0-hp-int'),
  '90030000-EW502HPA': values => 0,
  '90030000-EW502HPB': values => 0,
  '90030000-EW-502HPC': values => values.buildingWidth === '50-2-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-502HPD': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '50-2-hp-int'),
  '90030000-EW622HPA': values => 0,
  '90030000-EW-622HPC': values => values.buildingWidth === '62-2-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-622HPD': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '62-2-hp-int'),
  '90030000-EW670HPA': values => 0,
  '90030000-EW-670HPC': values => values.buildingWidth === '67-0-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-670HPD': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '67-0-hp-int'),
  '90030000-EW748A': values => 0,
  '90030000-EW-748C': values => values.buildingWidth === '74-8-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-748D': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '74-8-int'),
  '90030000-EW7511HPA': values => 0,
  '90030000-EW-7511HPC': values => values.buildingWidth === '75-11-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-7511HPD': values => calculateDoorOpeningEndwallBuildingLayoutQty(values, '75-11-hp-int'),
  '90030000-EW969A': values => 0,
  '90030000-EW-969B': values => values.buildingWidth === '96-9-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-969C': values => values.buildingWidth === '96-9-int' && values.doorEndwallKit && (values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] || values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit']) ? 1 : null,
  '90030000-EW-969D': values => values.buildingWidth === '96-9-int' && values.doorEndwallKit && (values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] || values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) ? 1 : null,
  '90030000-EW981HPA': values => 0,
  '90030000-EW-981HPB': values => values.buildingWidth === '98-1-hp-int' && values.doorEndwallKit && values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ? 1 : null,
  '90030000-EW-981HPC': values => values.buildingWidth === '98-1-hp-int' && values.doorEndwallKit && (values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] || values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit']) ? 1 : null,
  '90030000-EW-981HPD': values => values.buildingWidth === '98-1-hp-int' && values.doorEndwallKit && (values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] || values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) ? 1 : null,
  '90030000-EW1144A': values => 0,
  '90030000-EW1158HPA': values => 0,
  '90030000-EW1286A': values => 0,
  '90030000-EW12910HPA': values => 0,
  '90030000-EW-1B46-129': values => 0,
  '90030000-EW-1C46-129': values => 0,
  '90030000-EW-1D46-129': values => 0,
  '90030000-EW-1E46-129': values => 0,
  '9003000-EW-2A46-67': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && values.doorEndwallKit ? 1 : null,
  '9003000-EW-2B748-129': values => ['96-9-int', '98-1-hp-int'].includes(values.buildingWidth) && values.doorEndwallKit ? 1 : null,
  '90030000-CP01': values => 1,
  '90030000-CP01A': values => 1,
  '90030000-CP01B': values => 1,
  '90030000-CP01C': values => 1,
  '90030000-CP01D': values => 0,
  '90030000-CP01WIK': values => 0,
  '9003000-CP01GIK': values => 0,
  '90030000-CP01WSK': values => 0,
  '90030000-CP01WSK2': values => 0,
  '90030000-CP01GSK': values => 0,
  '90030000-CP01GSK2': values => 0,
  '90030000-CP01WH': values => 0,
  '90030000-CP01WH2': values => 0,
  '90030000-CP01GH': values => 0,
  '90030000-CP01GH2': values => 0,
  '90030000-CP02W46STD': values => 0,
  '90030000-CP02W46HP': values => 0,
  '90030000-CP02W128': values => 0,
  '90030000-CP02G46STD': values => 0,
  '90030000-CP02G46HP': values => 0,
  '90030000-CP02G128': values => 0,
  '90030000-CP0346W': values => 0,
  '90030000-CP0346HPW': values => 0,
  '90030000-CP0396W': values => 0,
  '90030000-CP0346G': values => 0,
  '90030000-CP0346HPG': values => 0,
  '90030000-CP0396G': values => 0,
  '90030000-CP0346C': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int'].includes(values.buildingWidth) && values.doorEndwallKit ? 1 : null,
  '90030000-CP0346HPC': values => ['46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int'].includes(values.buildingWidth) && values.doorEndwallKit ? 1 : null,
  '90030000-CP0396C': values => ['96-9-int', '98-1-hp-int'].includes(values.buildingWidth) && values.doorEndwallKit ? 1 : null,
  '90030000-CP4A': values => 0,
  '90030000-CP4B': values => calculateGatherDoorInstallManualQty(values),
  '90030000-CP4C': values => calculateGatherDoorInstallManualQty(values),
  '90030000-CP4D': values => calculateGatherDoorInstallManualQty(values),
  '90030000-CP4E': values => calculateGatherDoorInstallManualQty(values, true),
  '90030000-CP4F': values => calculateGatherDoorInstallManualQty(values, true),
  '90030000-CP4G': values => calculateGatherDoorInstallManualQty(values, true),
  '90030000-CP4H': values => calculateGatherDoorInstallManualQty(values, true),
  '90030000-CP4I': values => calculateGatherDoorInstallManualQty(values, true),
  '90030000-CP05KSTD': values => ['46-0-int', '50-2-int', '62-2-int', '67-0-int', '74-8-int', '96-9-int', '114-4-int', '128-6-int'].includes(values.buildingWidth) ? 1 : null,
  '90030000-CP05KHP': values => ['38-0-hp-int', '46-0-hp-int', '50-2-hp-int', '62-2-hp-int', '67-0-hp-int', '75-11-hp-int', '98-1-hp-int', '115-1-hp-int', '129-10-hp-int'].includes(values.buildingWidth) ? 1 : null
}

const calculateBrakeWinch = (values) => {
  return getOneWayDoors(values) + (2 * getTwoWayDoors(values))
}

const calculateCableAssembly = (width, length) => {
  return width * (1 + Math.ceil(length / 100))
}

const calculateEndwallBasePlate = (values) => {
  let qty = 0

  if (values.doorEndwallKit) {
    const conditions = {
      '38-0-int': 2,
      '46-0-int': 2,
      '50-2-int': 2,
      '62-2-int': 4,
      '67-0-int': 6,
      '74-8-int': 6,
      '88-0-int': 6,
      '96-9-int': 2,
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 4,
      '67-0-hp-int': 6,
      '75-11-hp-int': 6,
      '98-1-hp-int': 2
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      qty += values.doorEndwallKit * condition
    }
  }

  return qty + (getDoorKits(values) * 8)
}

const calculateEndwallColumn = (values) => {
  const conditions = {
    '96-9-int': 2,
    '98-1-hp-int': 2
  }

  const condition = conditions[values.buildingWidth]

  if (condition) {
    return 2 * (condition * values.doorEndwallKit)
  }

  return 0
}

const calculateEndwallCovers = (values, partNumber) => {
  if (values['fabric-endwall']) {
    let sum = 0

    if ((typeof partNumber === 'string') && partNumber.indexOf('EWC') === 0) {
      let buildCover = 'EWC'

      if (getDoorKits16(values)) {
        buildCover += '16C'
        sum += 2 * getDoorKits16(values)
      } else {
        buildCover +='20C'
        sum += 2 * (getDoorKits20(values) + getDoorKits24(values))
      }

      buildCover += values.buildingWidth.toUpperCase().split('-')[0]
      buildCover += values.endwallCoversColor.charAt(0).toUpperCase()
      buildCover += 'W'

      if (partNumber === buildCover) {
        return sum
      }
    }
  }
}

const calculateFenderWasher = (values) => {
  let qty = 0

  const doorPipe = (2 * (
    ((values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) * 8) +
    ((values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) * 7) +
    ((values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0) * 8) +
    ((values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) * 7) +
    ((values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0) * 6)
  ))

  qty += doorPipe

  if (values['fabric-endwall']) {
    qty += 2 * values.doorEndwallKit
  }

  qty += 2 * getDoorKits(values)

  return qty
}

const calculateGrade5Bolt = (values) => {
  return values.buildingEndConestogas !== 'none' ? 4 : 0
}

const calculateHorizontalPulley = (values) => {
  return (6 * getTwoWayDoors(values)) + (3 * getOneWayDoors(values))
}

const calculateLockWasher = (values) => {
  return (
    getStructuralBolts334(values) +
    getStructuralBolts335(values) +
    getStructuralBolts337(values) +
    getStructuralBolts338(values) +
    getStructuralBolts339(values) +
    getStructuralBolts340(values) +
    getStructuralBolts342(values)
  )
}

const calculateMainCover = (values) => {
  const conditions = {
    '38-0-int': 53.067,
    '46-0-int': 64.36,
    '50-2-int': 69.15,
    '62-2-int': 83.1,
    '67-0-int': 88.71,
    '74-8-int': 99.48,
    '88-0-int': 116.06,
    '96-9-int': 128.58,
    '46-0-hp-int': 64.36,
    '50-2-hp-int': 69.15,
    '62-2-hp-int': 83.1,
    '67-0-hp-int': 88.71,
    '75-11-hp-int': 99.48,
    '98-1-hp-int': 128.58
  }

  const condition = conditions[values.buildingWidth]

  if (condition) {
    return (((condition + (parseInt(values.premiumEaves) * 4)) * (values.buildingLength + 4)) * 1.082).toFixed(1)
  }
}

const calculateRafterInsert = (values, conditions) => {
  let value = 0
  let foundationConnectionOptions = {}

  if (conditions.group1 && conditions.group1[values.buildingWidth]) {
    foundationConnectionOptions = {
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 2,
      'beams-both-sides': 2,
      'one-side-long-legs-one-side-beams': 1
    }

    if (foundationConnectionOptions[values.foundationConnection]) {
      value = conditions.group1[values.buildingWidth]
    }
  }

  if (conditions.group2 && conditions.group2[values.buildingWidth]) {
    foundationConnectionOptions = {
      'both-sides-long-legs': 2,
      'one-side-long-legs-one-side-standard': 2,
      'one-side-long-legs-one-side-beams': 2
    }

    if (foundationConnectionOptions[values.foundationConnection]) {
      value = conditions.group2[values.buildingWidth]
    }
  }

  if (conditions.group3 && conditions.group3[values.buildingWidth]) {
    foundationConnectionOptions = {
      'beams-both-sides': 2,
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 1
    }

    if (foundationConnectionOptions[values.foundationConnection]) {
      value = conditions.group3[values.buildingWidth]
    }
  }

  if (conditions.group4 && conditions.group4[values.buildingWidth]) {
    foundationConnectionOptions = {
      'beams-both-sides': 2,
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 1
    }

    if (foundationConnectionOptions[values.foundationConnection]) {
      value = conditions.group4[values.buildingWidth]
    }
  }

  if (conditions.group5 && conditions.group5[values.buildingWidth]) {
    foundationConnectionOptions = {
      'both-sides-long-legs': 2,
      'one-side-long-legs-one-side-standard': 1,
      'one-side-long-legs-one-side-beams': 1
    }

    if (foundationConnectionOptions[values.foundationConnection]) {
      value = conditions.group5[values.buildingWidth]
    }
  }

  if (value) {
    return value * (spacingCalculation(values.buildingLength, foundationConnectionOptions[values.foundationConnection]) / 2)
  }

  return 0
}

const calculateRectangularPipeCap = (values) => {
  let qty = 0

  if (values.doorEndwallKit > 0) {
    qty += 2
    
    const conditions = {
      '38-0-int': 2,
      '46-0-int': 2,
      '50-2-int': 2,
      '62-2-int': 2,
      '67-0-int': 2,
      '74-8-int': 3,
      '88-0-int': 3,
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 2,
      '67-0-hp-int': 2,
      '75-11-hp-int': 3
    }
    
    if (conditions[values.buildingWidth]) {
      if (values['fabric-endwall']) qty += 2

      qty += 2 * (getDoorKits(values))
    }
  }

  return qty
}

const calculateTek3Screws = (values) => {
  let conditions = {}
  let condition
  let swagedPipe = 0
  let pullPipe = 0
  let kederTrackNormal = 0
  let kederTrackHigh = 0

  if (values.doorEndwallKit > 0) {
    conditions = {
      '38-0-int': 2,
      '46-0-int': 2,
      '50-2-int': 2,
      '62-2-int': 2,
      '67-0-int': 2,
      '74-8-int': 3,
      '88-0-int': 3,
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 2,
      '67-0-hp-int': 2,
      '75-11-hp-int': 3
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      swagedPipe += condition
    }
  }

  conditions = {
    '96-9-int': 4,
    '98-1-hp-int': 4
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    pullPipe += condition
  }

  conditions = {
    '38-0-int': 2.5,
    '46-0-int': 3,
    '50-2-int': 3,
    '62-2-int': 3.5,
    '67-0-int': 4,
    '74-8-int': 4.5,
    '88-0-int': 5,
    '46-0-hp-int': 3,
    '50-2-hp-int': 3,
    '62-2-hp-int': 3.5,
    '67-0-hp-int': 4,
    '75-11-hp-int': 4.5
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    kederTrackNormal += Math.ceil(spacingCalculation(values.buildingLength, condition))
  }

  conditions = {
    '96-9-int': 131,
    '98-1-hp-int': 141
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    kederTrackHigh += Math.ceil(spacingCalculation(values.buildingLength, Math.ceil(condition / 23.5)))
  }

  let rigidFrameToGirtClip = 0
  let endwallRafterConnector = 0
  let angleIron = 0
  let endwallGirtConnector = 0
  let endwallBasePlate = 0
  let endwallTubeInsert = 0 // B169, no equation // @TODO: Look into this

  conditions = {
    '96-9-int': 2,
    '98-1-hp-int': 6
  }

  condition = conditions[values.buildingWidth]

  if (condition && values.doorEndwallKit) {
    rigidFrameToGirtClip += values.doorEndwallKit * condition
  }

  if (values.doorEndwallKit) {
    conditions = {
      '38-0-int': 4,
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 6,
      '67-0-int': 6,
      '88-0-int': 8,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 6,
      '67-0-hp-int': 6,
      '75-11-hp-int': 8
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      endwallRafterConnector += values.doorEndwallKit * condition
    }

    if (values.buildingWidth === '74-8-int') {
      if (getTwoWayDoors(values)) {
        endwallRafterConnector += values.doorEndwallKit * 6
      } else {
        endwallRafterConnector += values.doorEndwallKit * 8
      }
    }
  }

  if (getVentPurlins(values)) {
    angleIron += parseInt(getOneWayDoors(values)) + (getVentPurlins(values) - 4)
  }

  if (values.doorEndwallKit) {
    conditions = {
      group1: {
        '38-0-int': 2,
        '46-0-int': 2,
        '50-2-int': 2,
        '62-2-int': 4,
        '67-0-int': 6,
        '88-0-int': 8,
        '46-0-hp-int': 2,
        '50-2-hp-int': 2,
        '62-2-hp-int': 4,
        '67-0-hp-int': 4,
        '75-11-hp-int': 8
      },
      group2: {
        '74-8-int': [6, 8],
        '98-1-hp-int': [8, 4],
        '96-9-int': [8, 4]
      }
    }

    condition = conditions.group1[values.buildingWidth]

    if (condition) {
      endwallGirtConnector += values.doorEndwallKit * condition
    }

    condition = conditions.group2[values.buildingWidth]

    if (condition) {
      if (getTwoWayDoors(values)) {
        endwallGirtConnector += values.doorEndwallKit * condition[0]
      }

      endwallGirtConnector += values.doorEndwallKit * condition[1]
    }
  }

  if (values.doorEndwallKit) {
    conditions = {
      '38-0-int': 2,
      '46-0-int': 2,
      '50-2-int': 2,
      '62-2-int': 4,
      '67-0-int': 6,
      '74-8-int': 6,
      '88-0-int': 6,
      '96-9-int': 2,
      '46-0-hp-int': 2,
      '50-2-hp-int': 2,
      '62-2-hp-int': 4,
      '67-0-hp-int': 6,
      '75-11-hp-int': 6,
      '98-1-hp-int': 2
    }

    condition = conditions[values.buildingWidth]

    if (condition) {
      endwallBasePlate += values.doorEndwallKit * condition
    }
  }

  endwallBasePlate += (getDoorKits(values) * 8)

  let doorPipe = 0
  let doorTrack16 = 0
  let doorTrack20 = 0
  let pulleyPlate = 0
  let fenderWasher = calculateFenderWasher(values)

  doorPipe += (
    ((values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) * 8) +
    ((values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) * 7) +
    ((values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0) * 8) +
    ((values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) * 7) +
    ((values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0) * 6)
  )

  doorTrack16 += (
    ((values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) * 3) +
    ((values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0) * 3) +
    ((values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0) * 2)
  )

  doorTrack20 += (
    ((values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) * 2) +
    ((values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) * 2)
  )

  pulleyPlate += getDoorKits(values) * 3

  return Math.ceil((
    (swagedPipe * 8) +
    (pullPipe * 8) +
    (kederTrackNormal * 36) +
    (kederTrackHigh * 36) +
    (rigidFrameToGirtClip * 12) +
    (endwallRafterConnector * 2) +
    (angleIron * 20) +
    (endwallGirtConnector * 8) +
    (endwallBasePlate * 8) +
    (endwallTubeInsert * 8) +
    (doorPipe * 2) +
    (doorTrack16 * 17) +
    (doorTrack20 * 21) +
    (pulleyPlate * 8) +
    (fenderWasher)
  ) * 1.05)
}

const calculateThreadedRod = (values) => {
  const legs = getLegs(values)

  if (values['5-8ths-threaded-rods'] && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
    let qty = 0

    if (values['wall-brackets'] === '8') {
      const basePlates = legs
      const singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        return Math.ceil((basePlates - 2) / 2) * 3
      }

      return Math.ceil(basePlates - 4) * 3
    }

    if (values['wall-brackets'] === '4.5') {
      let basePlates = (legs - 4)
      const singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        basePlates = (basePlates / 2)
      }

      qty += Math.ceil(basePlates)

      if (values.buildingWidth === '96-9-int' || values.buildingWidth === '98-1-hp-int') {
        qty += spacingCalculation(values.buildingLength, 2) - 4
      }

      return qty * 3
    }
  }

  return 0
}

const calculateWallBracketTPanel = (values) => {
  const legs = getLegs(values)

  if (values['wall-brackets'] === '4.5') {
    let qty = 0

    if ((values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      let basePlates = (legs - 4)
      const singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        basePlates = (basePlates / 2)
      }

      qty += basePlates
    }

    if (values.buildingWidth === '96-9-int') {
      qty += spacingCalculation(values.buildingLength, 2) - 4
    }

    if (values.buildingWidth === '98-1-hp-int') {
      qty += spacingCalculation(values.buildingLength, 2) - 4
    }

    return qty
  }

  return 0
}

const spacingCalculation = (length, multiplier, addition) => {
  return (((length / 16) + (addition ?? 1)) * multiplier)
}

const getCableAssembly = (values) => {
  let qty = 0

  let conditions = {
    '46-0-int': 8,
    '50-2-int': 8,
    '62-2-int': 4,
    '67-0-int': 4,
    '46-0-hp-int': 8,
  }

  let condition = conditions[values.buildingWidth]

  if (condition) {
    qty += calculateCableAssembly(condition, values.buildingLength)
  }

  conditions = {
    '46-0-int': 8,
    '50-2-int': 8,
    '74-8-int': 4,
    '88-0-int': 6,
    '96-9-int': 4,
    '46-0-hp-int': 4,
    '50-2-hp-int': 12,
    '62-2-hp-int': 8,
    '67-0-hp-int': 4,
    '75-11-hp-int': 4,
    '98-1-hp-int': 4
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty += calculateCableAssembly(condition, values.buildingLength)
  }

  conditions = {
    '62-2-int': 4,
    '96-9-int': 4,
    '62-2-hp-int': 4,
    '67-0-hp-int': 4,
    '98-1-hp-int': 4
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty += calculateCableAssembly(condition, values.buildingLength)
  }

  conditions = {
    '62-2-int': 4,
    '67-0-int': 8,
    '74-8-int': 8,
    '88-0-int': 8,
    '96-9-int': 8,
    '62-2-hp-int': 4,
    '67-0-hp-int': 4,
    '75-11-hp-int': 8,
    '98-1-hp-int': 8
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty += calculateCableAssembly(condition, values.buildingLength)
  }

  conditions = {
    '38-0-int': 6
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty += calculateCableAssembly(condition, values.buildingLength)
  }

  return qty
}

const getDoorKits = (values) => {
  return getOneWayDoors(values) + getTwoWayDoors(values)
}

const getDoorKits16 = (values) => {
  return (values['16-0-x-up-to-16-tall-1-way-gather-door-kit'] ?? 0)
}

const getDoorKits20 = (values) => {
  return  (values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ?? 0) + (values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0)
}

const getDoorKits24 = (values) => {
  return (values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ?? 0) + (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0)
}

const getLegs = (values) => {
  let sum = 0

  if (values.buildingWidth === '38-0-int') {
    sum += spacingCalculation(values.buildingLength, 2)
  }

  const foundationConnectionOptionsB61 = {
    'baseplates-only': 2,
    'beams-one-side-one-side-standard': 2,
    'one-side-long-legs-one-side-standard': 2,
    'beams-both-sides': 2,
    'one-side-long-legs-one-side-beams': 1
  }

  const buildingWidthArrayB61 = [
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int'
  ]

  const foundationConnectionOptionB61 = foundationConnectionOptionsB61[values.foundationConnection]

  if (buildingWidthArrayB61.includes(values.buildingWidth) && foundationConnectionOptionB61) {
    sum += spacingCalculation(values.buildingLength, foundationConnectionOptionB61)
  }

  const foundationConnectionOptionsB62 = {
    'both-sides-long-legs': 2,
    'one-side-long-legs-one-side-standard': 2,
    'one-side-long-legs-one-side-beams': 2
  }

  const buildingWidthArrayB62 = [
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int'
  ]

  const foundationConnectionOptionB62 = foundationConnectionOptionsB62[values.foundationConnection]

  if (buildingWidthArrayB62.includes(values.buildingWidth) && foundationConnectionOptionB62) {
    sum += spacingCalculation(values.buildingLength, foundationConnectionOptionB62)
  }

  const foundationConnectionOptionsB74 = {
    'beams-both-sides': 2,
    'baseplates-only': 2,
    'beams-one-side-one-side-standard': 2,
    'one-side-long-legs-one-side-standard': 1
  }

  const buildingWidthArrayB74 = [
    '74-8-int',
    '88-0-int'
  ]

  const foundationConnectionOptionB74 = foundationConnectionOptionsB74[values.foundationConnection]

  if (buildingWidthArrayB74.includes(values.buildingWidth) && foundationConnectionOptionB74) {
    sum += spacingCalculation(values.buildingLength, foundationConnectionOptionB74)
  }

  if (values.buildingWidth === '75-11-hp-int') {
    const foundationConnectionOptionsB75 = {
      'both-sides-long-legs': 2,
      'one-side-long-legs-one-side-standard': 1,
      'one-side-long-legs-one-side-beams': 1
    }

    const foundationConnectionOptionB75 = foundationConnectionOptionsB75[values.foundationConnection]

    if (foundationConnectionOptionB75) {
      sum += spacingCalculation(values.buildingLength, foundationConnectionOptionB75)
    }
  }

  return sum
}

const getBasePlatesHigh = (values) => {
  let qty = 0

  if (values.buildingWidth === '96-9-int') {
    qty += spacingCalculation(values.buildingLength, 2)
  }

  if (values.buildingWidth === '98-1-hp-int') {
    qty += spacingCalculation(values.buildingLength, 2) + 4
  }

  return qty
}

const getBasePlatesNormal = (values) => {
  let qty = 0
  const legs = getLegs(values)

  if ((values['wall-brackets'] !== '8') && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
    const basePlates = (legs - 4)
    const basePlates2 = legs
    const singleSideBeams = [
      'beams-one-side-one-side-standard',
      'one-side-long-legs-one-side-beams'
    ]

    if (singleSideBeams.includes(values.foundationConnection)) {
      qty += Math.ceil(basePlates / 2)
      // qty += Math.ceil((basePlates2 - 2) / 2)
    } else {
      qty += Math.ceil(basePlates2 - 4)
    }
  }

  if (values.buildingWidth !== '38-0-int' && legs) {
    const foundationConnections = {
      'baseplates-only': 4,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 4,
      'beams-both-sides': 0,
      'one-side-long-legs-one-side-beams': 2
    }

    qty += foundationConnections[values.foundationConnection] ?? 0
  }

  return qty
}

const getOneWayDoors = (values) => {
  return (
    parseInt(values['16-0-x-up-to-16-tall-1-way-gather-door-kit']) +
    parseInt(values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit']) +
    parseInt(values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'])
  )
}

const getPremiumEaves = (values) => {
  return spacingCalculation(values.buildingLength, parseInt(values.premiumEaves))
}

const getRafterInserts = (values) => {
  let qty = 0

  let conditions = {
    '96-9-int': 4,
    '98-1-hp-int': 4
  }

  let condition = conditions[values.buildingWidth]

  if (condition) {
    qty += spacingCalculation(values.buildingLength, condition)
  }

  conditions = {
    '96-9-int': 6,
    '98-1-hp-int': 8
  }

  condition = conditions[values.buildingWidth]

  if (condition) {
    qty += spacingCalculation(values.buildingLength, condition)
  }

  if (values.buildingWidth === '96-9-int') {
    qty += spacingCalculation(values.buildingLength, 2)
  }

  return qty
}

const getStructuralBolts = (values) => {
  return getRafterInserts(values) ? (getRafterInserts(values) * 6) + 6 : 0
}

const getStructuralBolts334 = (values) => {
  let qty = 0
  let integrity10CattleBeams = 0
  let basePlates = 0
  let cableAngle =  (4 + (4 * Math.ceil(values.buildingLength / 100)))
  let endwallColumnToRafterPlate = calculateEndwallColumn(values)
  let columnBoltOnClips = 0
  const legs = getLegs(values)

  let foundationConnectionOptions = {
    'beams-both-sides': 2,
    'beams-one-side-one-side-standard': 1,
    'one-side-long-legs-one-side-beams': 1,
  }

  let buildingWidthArray = [
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int'
  ]

  let condition = foundationConnectionOptions[values.foundationConnection]

  if (buildingWidthArray.includes(values.buildingWidth) && condition) {
    integrity10CattleBeams += spacingCalculation(values.buildingLength, condition)
  }

  if (values.buildingWidth !== '38-0-int' && legs) {
    let foundationConnections = {
      'baseplates-only': 4,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 4,
      'beams-both-sides': 0,
      'one-side-long-legs-one-side-beams': 2
    }

    basePlates += foundationConnections[values.foundationConnection] ?? 0
  }

  if ((values['wall-brackets'] === '8') && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
    let basePlates = legs
    let singleSideBeams = [
      'beams-one-side-one-side-standard',
      'one-side-long-legs-one-side-beams'
    ]

    if (singleSideBeams.includes(values.foundationConnection)) {
      basePlates += Math.ceil((basePlates - 2) / 2)
    }

    basePlates += Math.ceil(basePlates - 4)
  }

  if (values['wall-brackets'] === '4.5') {
    if ((values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      let basePlateLegs = (legs - 4)
      let singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        basePlateLegs = (basePlateLegs / 2)
      }

      basePlates += basePlateLegs
    }

    if (values.buildingWidth === '96-9-int') {
      basePlates += spacingCalculation(values.buildingLength, 2) - 4
    }

    if (values.buildingWidth === '98-1-hp-int') {
      basePlates += spacingCalculation(values.buildingLength, 2) - 4
    }
  }

  if (values.buildingWidth === '96-9-int') {
    basePlates += spacingCalculation(values.buildingLength, 2) - 4
  }

  if (values.buildingWidth === '98-1-hp-int') {
    basePlates += spacingCalculation(values.buildingLength, 2) - 4
  }

  if (values.buildingWidth === '96-9-int') {
    basePlates += 4
  }

  if (values.buildingWidth === '98-1-hp-int') {
    basePlates += 8
  }

  buildingWidthArray = {
    '96-9-int': [14, 12],
    '98-1-hp-int': [18, 16]
  }

  condition = buildingWidthArray[values.buildingWidth]

  if (values.doorEndwallKit && condition) {
    if (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit']) {
      columnBoltOnClips += values.doorEndwallKit * condition[0]
    } else {
      columnBoltOnClips += values.doorEndwallKit * condition[1]
    }
  }

  if (integrity10CattleBeams) {
    qty += cableAngle - (basePlates ? cableAngle / 2 : 0)
  }

  qty += 2 * endwallColumnToRafterPlate
  qty += 4 * columnBoltOnClips

  if (integrity10CattleBeams || endwallColumnToRafterPlate || columnBoltOnClips) {
    qty += 4
  }

  return qty
}

const getStructuralBolts335 = (values) => {
  let qty = 0
  let sum1 = 0
  let sum2 = 0
  let sum3 = 0

  if (values.buildingWidth === '38-0-int') {
    sum1 += spacingCalculation(values.buildingLength, 2)
  }

  const foundationConnectionOptions = {
    'beams-both-sides': 2,
    'beams-one-side-one-side-standard': 1,
    'one-side-long-legs-one-side-beams': 1,
  }

  const buildingWidthArray = [
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int'
  ]

  const condition = foundationConnectionOptions[values.foundationConnection]

  if (buildingWidthArray.includes(values.buildingWidth) && condition) {
    sum1 += spacingCalculation(values.buildingLength, condition)
  }

  sum1 += getBasePlatesNormal(values)
  sum2 += getBasePlatesHigh(values)
  sum3 += calculateEndwallColumn(values)

  qty += 2 * sum1
  qty += 3 * sum2
  qty += 4 * sum3

  return qty + 4
}

const getStructuralBolts337 = (values) => {
  let qty = 0
  let sum1 = (2 * getPremiumEaves(values))
  let sum2 = 0

  if (values.buildingWidth === '38-0-int') {
    sum1 += spacingCalculation(values.buildingLength, 2)
    sum2 += spacingCalculation(values.buildingLength, 2)
    sum1 += spacingCalculation(values.buildingLength, 4)
    sum2 += spacingCalculation(values.buildingLength, 4)
    sum1 += calculateCableAssembly(4, values.buildingLength)
    sum2 += calculateCableAssembly(4, values.buildingLength)
  }

  qty += 2 * sum1

  if (sum2) qty += 4

  return qty
}

const getStructuralBolts338 = (values) => {
  let qty = 0
  let num1 = 0
  let num2 = 0
  let num3 = 0 // Gable Girt, B154 // @TODO: Look into this
  let num4 = 0  // Angled Gable Girt Clip, B155 // @TODO: Look into this

  if (values.buildingWidth === '38-0-int') {
    num1 += spacingCalculation(values.buildingLength, 2, 0)
    num2 += spacingCalculation(values.buildingLength, 4)

    if (values.buildingLength > 192) {
      num1 += Math.ceil(spacingCalculation(values.buildingLength, 4, 0) + (((values.buildingLength/192) * 4) + 4))
    } else {
      num1 += Math.ceil(spacingCalculation(values.buildingLength, 4, 0) + 8)
    }
  } else {
    num1 += 4

    let buildingWidthArray = [
      '96-9-int',
      '98-1-hp-int'
    ]

    if (buildingWidthArray.includes(values.buildingWidth)) {
      num1 += 4
    }

    let conditions = {
      group1: {
        '46-0-int': 4
      },
      group2: {
        '46-0-hp-int': 4,
        '50-2-hp-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      },
      group3: {
        '74-8-int': 4
      },
      group4: {
        '88-0-int': 6
      },
      group5: {
        '75-11-hp-int': 6
      }
    }

    num1 += calculateRafterInsert(values, conditions)

    conditions = {
      group1: {
        '50-2-int': 4,
        '62-2-int': 4,
        '67-0-int': 4,
      },
      group2: {
        '50-2-hp-int': 2,
        '62-2-hp-int': 2,
        '67-0-hp-int': 2
      }
    }

    num1 += calculateRafterInsert(values, conditions)
  }

  qty += 4 * num1
  qty += 2 * num2
  qty += 2 * num3
  qty += num4

  return qty + 4
}

const getStructuralBolts339 = (values) => {
  let qty = 0
  let sum1 = 0
  let sum2 = 0

  let buildingWidthsArray = {
    '46-0-int': 5,
    '50-2-int': 7,
    '62-2-int': 7,
    '67-0-int': 7,
    '74-8-int': 9,
    '88-0-int': 11,
    '46-0-hp-int': 7,
    '50-2-hp-int': 7,
    '62-2-hp-int': 9,
    '67-0-hp-int': 9,
    '75-11-hp-int': 11
  }

  let buildingWidthCondition = buildingWidthsArray[values.buildingWidth]

  if (buildingWidthCondition) {
    sum1 += Math.ceil(spacingCalculation(values.buildingLength, buildingWidthCondition, 0))
  }

  let conditions = {
    group1: {
      '46-0-int': 4,
      '50-2-int': 4
    },
    group2: {
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 2,
      '67-0-hp-int': 2
    },
    group3: {
      '74-8-int': 6
    },
    group4: {
      '88-0-int': 8
    },
    group5: {
      '75-11-hp-int': 6
    }
  }

  sum1 += calculateRafterInsert(values, conditions)

  conditions = {
    group1: {
      '62-2-int': 4,
      '67-0-int': 4
    },
    group2: {
      '62-2-hp-int': 2,
      '67-0-hp-int': 2
    }
  }

  sum1 += calculateRafterInsert(values, conditions)

  if (values.buildingWidth === '88-0-int') return 2 * 2

  if (values.doorEndwallKit) {
    buildingWidthsArray = {
      '62-2-int': 2,
      '67-0-int': 2,
      '74-8-int': 2,
      '62-2-hp-int': 2,
      '67-0-hp-int': 2,
      '75-11-hp-int': 2
    }

    buildingWidthCondition = buildingWidthsArray[values.buildingWidth]

    if (buildingWidthCondition) {
      sum1 += 2 * (values.doorEndwallKit * buildingWidthCondition)
    }
  }

  buildingWidthsArray = {
    '38-0-int': [null, null, 4],
    '46-0-int': [4, 10, 4],
    '50-2-int': [6, 10, 4],
    '62-2-int': [6, 10, 6],
    '67-0-int': [6, 10, 6],
    '74-8-int': [8, 10, null],
    '88-0-int': [10, 12, 8],
    '46-0-hp-int': [4, 10, 4],
    '50-2-hp-int': [4, 10, 4],
    '62-2-hp-int': [6, 10, 6],
    '67-0-hp-int': [6, 10, 6],
    '75-11-hp-int': [8, 10, 8]
  }

  buildingWidthCondition = buildingWidthsArray[values.buildingWidth]

  if (buildingWidthCondition) {
    if (buildingWidthCondition[0]) {
      sum2 += Math.ceil(spacingCalculation(values.buildingLength, buildingWidthCondition[0]))
    }

    if (buildingWidthCondition[1]) {
      sum2 += calculateCableAssembly(buildingWidthCondition[1], values.buildingLength)
    }

    if (buildingWidthCondition[2]) {
      sum2 += values.doorEndwallKit * buildingWidthCondition[2]
    }
    
    if (!buildingWidthCondition[2] && values.buildingWidth === '74-8-int') {
      if (getTwoWayDoors(values)) {
        sum2 += values.doorEndwallKit * 6
      } else {
        sum2 += values.doorEndwallKit * 8
      }
    }
  }

  qty += 4 * sum1
  qty += 2 * sum2

  return qty + 4
}

const getStructuralBolts340 = (values) => {
  let qty = 0
  let sum = 0
  let buildingWidthArray = []
  let buildingWidthCondition
  let foundationCondition
  let num1 = 0
  let num2 = getDoorKits(values)
  let num3 = 0

  if (parseInt(values.premiumEaves) < 2) {
    buildingWidthArray = [
      '46-0-int',
      '50-2-int',
      '62-2-int',
      '67-0-int'
    ]

    const foundationConnectionOptions61 = {
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 2,
      'beams-both-sides': 2,
      'one-side-long-legs-one-side-beams': 1
    }

    const foundationConnectionOptions62 = {
      'both-sides-long-legs': 2,
      'one-side-long-legs-one-side-standard': 2,
      'one-side-long-legs-one-side-beams': 2
    }

    foundationCondition = foundationConnectionOptions61[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationCondition) {
      sum += spacingCalculation(values.buildingLength, foundationCondition)
    }

    foundationCondition = foundationConnectionOptions62[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationCondition) {
      sum += spacingCalculation(values.buildingLength, foundationCondition)
    }

    num1 += sum / (parseInt(values.premiumEaves) + 1)
  }

  num1 += getPremiumEaves(values)

  if (parseInt(values.premiumEaves) < 2) {
    sum = 0

    buildingWidthArray = [
      '74-8-int',
      '88-0-int'
    ]

    const foundationConnectionOptions74 = {
      'beams-both-sides': 2,
      'baseplates-only': 2,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 1
    }

    foundationCondition = foundationConnectionOptions74[values.foundationConnection]

    if (buildingWidthArray.includes(values.buildingWidth) && foundationCondition) {
      sum += spacingCalculation(values.buildingLength, foundationCondition)
    }

    if (values.buildingWidth === '74-8-int') {
      const foundationConnectionOptions75 = {
        'both-sides-long-legs': 2,
        'one-side-long-legs-one-side-standard': 1,
        'one-side-long-legs-one-side-beams': 1
      }

      foundationCondition = foundationConnectionOptions75[values.foundationConnection]

      if (foundationCondition) {
        sum += spacingCalculation(values.buildingLength, foundationCondition)
      }
    }

    num1 += sum / (parseInt(values.premiumEaves) + 1)
  }

  if (values.doorEndwallKit) {
    buildingWidthArray = {
      '38-0-int': 4,
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 6,
      '67-0-int': 6,
      '88-0-int': 8,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 6,
      '67-0-hp-int': 6,
      '75-11-hp-int': 8
    }

    buildingWidthCondition = buildingWidthArray[values.buildingWidth]

    if (buildingWidthCondition) {
      num1 += values.doorEndwallKit * buildingWidthCondition
    }

    if (values.buildingWidth === '74-8-int') {
      if (getTwoWayDoors(values)) {
        num1 += values.doorEndwallKit * 6
      } else {
        num1 += values.doorEndwallKit * 8
      }
    }
  }

  if (values.doorEndwallKit) {
    buildingWidthArray = {
      group1: {
        '38-0-int': 2,
        '46-0-int': 2,
        '50-2-int': 2,
        '62-2-int': 4,
        '67-0-int': 6,
        '88-0-int': 8,
        '46-0-hp-int': 2,
        '50-2-hp-int': 2,
        '62-2-hp-int': 4,
        '67-0-hp-int': 4,
        '75-11-hp-int': 8
      },
      group2: {
        '74-8-int': [6, 8],
        '98-1-hp-int': [8, 4],
        '96-9-int': [8, 4]
      }
    }

    buildingWidthCondition = buildingWidthArray.group1[values.buildingWidth]

    if (buildingWidthCondition) {
      num1 += values.doorEndwallKit * buildingWidthCondition
    }

    buildingWidthCondition = buildingWidthArray.group2[values.buildingWidth]

    if (buildingWidthCondition) {
      if (getTwoWayDoors(values)) {
        num1 += values.doorEndwallKit * buildingWidthCondition[0]
      } else {
        num1 += values.doorEndwallKit * buildingWidthCondition[1]
      }
    }
  }

  if (values.buildingWidth !== '38-0-int') num3 += getVentPurlins(values)

  // @TODO: Look into this
  // B154, B156, B157 do not have equations
  // IF(B156>0,B154*2,B154*1)+B156*2+B157*1

  qty += 2 * num1
  qty += 4 * num2

  if (num3) qty += 4 + (2 * num3)

  return qty + 4
}

const getStructuralBolts342 = (values) => {
  let qty = 0
  let num1 = 0
  let num2 = 0
  let num3 = 0
  let num4 = 0

  let buildingWidthArray = {
    '96-9-int': [14, 2],
    '98-1-hp-int': [14, 2]
  }

  let buildingWidthCondition = buildingWidthArray[values.buildingWidth]

  if (buildingWidthCondition) {
    num1 += Math.ceil(spacingCalculation(values.buildingLength, 11))
    num1 += calculateCableAssembly(buildingWidthCondition[0], values.buildingLength)

    num2 += Math.ceil(spacingCalculation(values.buildingLength, 11, 0))
    num2 += 2 * (buildingWidthCondition[1] * values.doorEndwallKit)

    num3 += spacingCalculation(values.buildingLength, 2, 0)

    num4 += 2 * (buildingWidthCondition[1] * values.doorEndwallKit)
  } else {
    num1 += spacingCalculation(values.buildingLength, 2, 0)
  }

  qty += 2 * num1
  qty += 4 * num2
  qty += 4 * num3
  qty += 4 * num4

  return qty + 4
}

const getStructuralBolts343 = (values) => {
  // @TODO: Look into this
  // B158, B159, B160 do not have equations
  // =IF(B158>0,(B158*2)+SUM(B159:B160*1)+4,0)+A343
}

const getStructuralBolts347 = (values) => {
  let allBasePlates = getBasePlatesHigh(values) + getBasePlatesNormal(values) + calculateWallBracketTPanel(values)
  let integrity10CattleBeams = 0
  let basePlates = 0
  let cableAngle =  (4 + (4 * Math.ceil(values.buildingLength / 100)))
  const legs = getLegs(values)

  let foundationConnectionOptions = {
    'beams-both-sides': 2,
    'beams-one-side-one-side-standard': 1,
    'one-side-long-legs-one-side-beams': 1,
  }

  let buildingWidthArray = [
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int'
  ]

  let foundationCondition = foundationConnectionOptions[values.foundationConnection]

  if (buildingWidthArray.includes(values.buildingWidth) && foundationCondition) {
    integrity10CattleBeams += spacingCalculation(values.buildingLength, foundationCondition)
  }

  if (values.buildingWidth !== '38-0-int' && legs) {
    let foundationConnections = {
      'baseplates-only': 4,
      'beams-one-side-one-side-standard': 2,
      'one-side-long-legs-one-side-standard': 4,
      'beams-both-sides': 0,
      'one-side-long-legs-one-side-beams': 2
    }

    basePlates += foundationConnections[values.foundationConnection] ?? 0
  }

  if ((values['wall-brackets'] === '8') && (values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
    let basePlates = legs
    let singleSideBeams = [
      'beams-one-side-one-side-standard',
      'one-side-long-legs-one-side-beams'
    ]

    if (singleSideBeams.includes(values.foundationConnection)) {
      basePlates += Math.ceil((basePlates - 2) / 2)
    }

    basePlates += Math.ceil(basePlates - 4)
  }

  if (values['wall-brackets'] === '4.5') {
    if ((values.foundationConnection !== 'beams-both-sides') && (legs > 0)) {
      let basePlateLegs = (legs - 4)
      let singleSideBeams = [
        'beams-one-side-one-side-standard',
        'one-side-long-legs-one-side-beams'
      ]

      if (singleSideBeams.includes(values.foundationConnection)) {
        basePlateLegs = (basePlateLegs / 2)
      }

      basePlates += basePlateLegs
    }

    if (values.buildingWidth === '96-9-int') {
      basePlates += spacingCalculation(values.buildingLength, 2) - 4
    }

    if (values.buildingWidth === '98-1-hp-int') {
      basePlates += spacingCalculation(values.buildingLength, 2) - 4
    }
  }

  if (values.buildingWidth === '96-9-int') {
    basePlates += spacingCalculation(values.buildingLength, 2) - 4
  }

  if (values.buildingWidth === '98-1-hp-int') {
    basePlates += spacingCalculation(values.buildingLength, 2) - 4
  }

  if (values.buildingWidth === '96-9-int') {
    basePlates += 4
  }

  if (values.buildingWidth === '98-1-hp-int') {
    basePlates += 8
  }

  if (allBasePlates) {
    return cableAngle - (integrity10CattleBeams ? cableAngle / 2 : 0)
  }

  return 0
}

const getStructuralBolts356 = (values) => {
  // @TODO: Look into this
  // B159 does not have an equation
  // =IF(B159>0,(B159*1)+1,0)+A356
}

const getStructuralBolts357 = (values) => {
  // @TODO: Look into this
  // B160 does not have an equation
  // =IF(B160>0,(B160*1)+1,0)+A357
}

const getTwoWayDoors = (values) => {
  return (values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit'] ?? 0) + (values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ?? 0)
}

const getVentPurlins = (values) => {
  let buildingWidthArray = [
    '38-0-int',
    '46-0-int',
    '50-2-int',
    '62-2-int',
    '67-0-int',
    '74-8-int',
    '88-0-int',
    '46-0-hp-int',
    '50-2-hp-int',
    '62-2-hp-int',
    '67-0-hp-int',
    '75-11-hp-int'
  ]

  if (buildingWidthArray.includes(values.buildingWidth)) {
    return spacingCalculation(values.buildingLength, 2, 0)
  }

  return 0
}

const getWinches = (values, side) => {
  let right = 0
  let left = 0

  if (values.doorEndwallKit) {
    const conditions = {
      '38-0-int': 4,
      '46-0-int': 4,
      '50-2-int': 4,
      '62-2-int': 4,
      '67-0-int': 4,
      '74-8-int': 8,
      '88-0-int': 8,
      '96-9-int': 8,
      '46-0-hp-int': 4,
      '50-2-hp-int': 4,
      '62-2-hp-int': 4,
      '67-0-hp-int': 4,
      '75-11-hp-int': 8,
      '98-1-hp-int': 8
    }

    const condition = conditions[values.buildingWidth]

    if (condition) {
      right += values.doorEndwallKit * condition
      right += getDoorKits(values) * 2
      right += values.buildingEndConestogas !== 'none' ? 2 : 0
    }
  }

  left += values.buildingEndConestogas !== 'none' ? 2 : 0

  if (!side) return right + left
  if (side === 'right') return right
  if (side === 'left') return left
}

const calculateDoorOpeningEndwallBuildingLayoutQty = (values, targetBuildingWidth) => {
  return values.buildingWidth === targetBuildingWidth && values.doorEndwallKit && (
    values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ||
    values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ||
    values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ||
    values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit']
  ) ? 1 : null
}

const calculateGatherDoorInstallManualQty = (values, twoWay) => {
  if (twoWay) {
    return (
      values['19-7-1-2-x-up-to-24-tall-2-way-gather-door-kit'] ||
      values['19-7-1-2-x-up-to-20-tall-2-way-gather-door-kit']
    ) ? 1 : null
  } else {
    return (
      values['19-7-1-2-x-up-to-24-tall-1-way-gather-door-kit'] ||
      values['19-7-1-2-x-up-to-20-tall-1-way-gather-door-kit'] ||
      values['16-0-x-up-to-16-tall-1-way-gather-door-kit']
    ) ? 1 : null
  }
}