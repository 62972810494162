import * as ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import * as dayjs from 'dayjs'

import { convertStringToTitleCase, numberFormat } from '../helpers'

export const generateBuildingEstimateXLSX = (data, estimateOptions) => {
  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('Building Estimate')

  // Set column widths
  worksheet.getColumn('A').width = 40
  worksheet.getColumn('B').width = 40
  worksheet.getColumn('C').width = 20
  worksheet.getColumn('D').width = 20

  const headerCell = worksheet.getRow(1).getCell(1)
  headerCell.value = data.title
  headerCell.font = { size: 20 }

  worksheet.addRow() // Add an empty row under the header

  const estimateDetails = [
    {
      label: 'Created Date',
      value: dayjs(data.createdDate).format('M/D/YY h:mma')
    },
    {
      label: 'Last Modified Date',
      value: dayjs(data.modifiedDate).format('M/D/YY h:mma')
    },
    {
      label: 'Creator',
      value: data.creator ? (data.creator.first_name + ' ' + data.creator.last_name) : '(User Not Found)'
    },
    {
      label: 'Dealer',
      value: data.dealer ? (data.dealer.first_name + ' ' + data.dealer.last_name) : '(User Not Found)'
    },
    {
      label: 'Status',
      value: `${convertStringToTitleCase(data.status)}`
    },
    {
      label: 'Notes',
      value: data.inputData?.notes
    }
  ]

  estimateDetails.forEach((item) => {
    worksheet.addRow([ item.label, item.value ])
  })

  worksheet.addTable({
    name: 'BuildingOptions',
    ref: 'A' + String(4 + estimateDetails.length),
    style: {
      theme: null
    },
    columns: [
      { name: 'Option' },
      { name: 'Value' },
    ],
    rows: estimateOptions.map(item => [
      item.option,
      item.value
    ])
  })

  worksheet.addTable({
    name: 'BuildingParts',
    ref: 'A' + String(6 + estimateDetails.length + estimateOptions.length),
    style: {
      theme: null
    },
    columns: [
      { name: 'Part Number' },
      { name: 'Description' },
      { name: 'Required Qty' },
      { name: 'Add Qty' }
    ],
    rows: data.parts.map(part => {
      let displayDescription = part.description
      let displayPartNumber = part.partNumber ?? `ID #${part.id}`

      if (part.alternate) {
        const fieldValue = estimateOptions[part.alternate.field]

        if (fieldValue === part.alternate.value) {
          if (part.alternate.properties.description) {
            displayDescription = part.alternate.properties.description
          }

          if (part.alternate.properties.partNumber) {
            displayPartNumber = part.alternate.properties.partNumber
          }
        }
      }

      return [
        displayPartNumber,
        displayDescription,
        part.requiredQty,
        part.addQty
      ]
    })
  })

  worksheet.addRow() // Add an empty row under the parts table

  const totals = [
    {
      label: 'Sqft',
      value: numberFormat({
        value: data.totals.sqft,
        thousandSeparator: true,
        decimalScale: 0
      })
    },
    {
      label: 'Shipping Weight',
      value: numberFormat({
        value: data.totals.weight,
        thousandSeparator: true
      })
    },
    {
      label: 'Suggested Retail Price',
      value: numberFormat({
        value: data.totals.suggestedPrice,
        thousandSeparator: true,
        prefix: '$'
      })
    },
    {
      label: 'Dealer Discount',
      value: numberFormat({
        value: data.totals.discountPercentage,
        suffix: '%'
      })
    },
    {
      label: 'Dealer Price',
      value: numberFormat({
        value: data.totals.amountDue,
        thousandSeparator: true,
        prefix: '$'
      })
    },
    {
      label: 'Price Per Sqft',
      value: numberFormat({
        value: data.totals.pricePerSqFt,
        thousandSeparator: true,
        prefix: '$'
      })
    },
    {
      label: 'Deposit Required',
      value: numberFormat({
        value: data.totals.deposit,
        thousandSeparator: true,
        prefix: '$'
      })
    },
    {
      label: 'Amount Due at Pickup',
      value: numberFormat({
        value: data.totals.amountDueAtPickup,
        thousandSeparator: true,
        prefix: '$'
      })
    }
  ]

  totals.forEach((item) => {
    worksheet.addRow([ item.label, item.value ])
  })

  // Set styles for entire book
  workbook.eachSheet(sheet => {
    sheet.eachRow(row => {
      row.eachCell(cell => {
        if (!cell.font || !cell.font.size) {
          cell.font = Object.assign(cell.font || {}, { size: 14 })
        }
      })
    })
  })

  workbook.xlsx.writeBuffer().then((buffer) => {
    saveAs(
      new Blob([buffer], { type: 'application/octet-stream' }),
      `${data.title}.xlsx`
    )
  })
}