import React, { useState } from 'react'
import {
  Input,
  Table,
  Button,
  PageHeader
} from 'antd'

const GrainStorageCalcTable = () => {
  const [inputValues, setInputValues] = useState({})
  const [calculationResults, setCalculationResults] = useState({})

  const handleCalculate = () => {
    const width = parseFloat(inputValues['width'])
    const length = parseFloat(inputValues['length'])
    const grainHeight = parseFloat(inputValues['grainHeight'])
    const heightFactor = parseFloat(inputValues['heightFactor'])
    const bushels = grainHeight * length * width / 1.25
    const pileHeight = width * heightFactor
    const widthSquared = Math.pow(width, 2)
    const peakBushel = Math.round(.209 * widthSquared * pileHeight);
    const triangleLength = length - width
    const traingularBushel = .4 * width * pileHeight * triangleLength
    const totalVolume = bushels + peakBushel + traingularBushel

    setCalculationResults((prevResults) => ({
      ...prevResults,
      '1-1': bushels,
      '2-1': width,
      '2-2': pileHeight,
      '2-3': peakBushel,
      '3-1': .209,
      '3-2': widthSquared,
      '3-3': pileHeight,
      '4-1': .4,
      '4-2': width,
      '4-3': pileHeight,
      '4-4': triangleLength,
      '4-5': traingularBushel,
      '5': totalVolume
    }))
  }

  const handleInputChange = (key, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [key]: value,
    }))
  }

  const columns = [
    {
      title: 'Input',
      dataIndex: 'fieldTitle',
      render: (_, record) => <span>{record.fieldTitle}</span>,
    },
    {
      dataIndex: 'input',
      render: (_, record) => (
        <Input placeholder="Enter value" onChange={(e) => handleInputChange(record.key, e.target.value)} />
      ),
    },
  ]

  const data = [
    { key: 'width', fieldTitle: 'Width of Building (ft):' },
    { key: 'length', fieldTitle: 'Length of Building (ft):' },
    { key: 'grainHeight', fieldTitle: 'Grain height at side wall (ft):' },
    { key: 'heightFactor', fieldTitle: 'Height Factor (grain dependant see below):' },
  ]

  return (
    <div style={{ textAlign: 'center' }}>
      <Table columns={columns} dataSource={data} pagination={false} />
      <Button type="primary" style={{ margin: 15 }} onClick={handleCalculate}>Calculate</Button>
      <GrainStorageResultTable calculationResults={calculationResults} />
    </div>
  )
}

const GrainStorageResultTable = ({ calculationResults }) => {
  const columns = [
    {
      title: 'Output',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => {
        if (record.key === '5') {
          return <strong style={{ fontSize: '20px' }}>{text}</strong>
        }

        return record.children ? <strong>{text}</strong> : text
      },
      width: '50%',
    },
    {
      title: '',
      dataIndex: 'value',
      render: (_, record) => (
        <>
          {record.key in calculationResults && !isNaN(calculationResults[record.key]) ? calculationResults[record.key].toLocaleString() : record.initialValue}
        </>
      ),
      key: 'value',
      align: 'center',
    },
  ]

  const data = [
    {
      key: '1',
      label: 'Volume of rectangular wall height',
      value: '',
      children: [
        {
          key: '1-1',
          label: 'Bushels calculation',
          initialValue: 0,
        },
      ],
    },
    {
      key: '2',
      label: 'Peaked volume',
      value: '',
      children: [
        {
          key: '2-1',
          label: 'Width',
          initialValue: 0,
        },
        {
          key: '2-2',
          label: 'Height of Pile',
          initialValue: 0,
        },
        {
          key: '2-3',
          label: 'Peak Bushel Calculation',
          initialValue: 0,
        },
      ],
    },
    {
      key: '3',
      label: '1/2 Pyramidal section volume',
      value: '',
      children: [
        {
          key: '3-1',
          label: 'Calculator',
          initialValue: 0,
        },
        {
          key: '3-2',
          label: 'Width squared',
          initialValue: 0,
        },
        {
          key: '3-3',
          label: 'Height',
          initialValue: 0,
        },
      ],
    },
    {
      key: '4',
      label: 'Triangular Shaped Volume',
      value: '',
      children: [
        {
          key: '4-1',
          label: 'Calculator',
          initialValue: 0,
        },
        {
          key: '4-2',
          label: 'Width',
          initialValue: 0,
        },
        {
          key: '4-3',
          label: 'Height',
          initialValue: 0,
        },
        {
          key: '4-4',
          label: 'Length of complete triangle',
          initialValue: 0,
        },
        {
          key: '4-5',
          label: 'Triangular bushel calculator',
          initialValue: 0,
        },
      ],
    },
    {
      key: '5',
      label: 'Total Building Volume',
      initialValue: 0,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination={false}
    />
  )
}


const GrainStorageHeightChart = () => {
  const columns = [
    {
      title: 'Grain',
      dataIndex: 'grain',
      key: 'grain',
    },
    {
      title: 'Angle of repose',
      dataIndex: 'angleOfRepose',
      key: 'angleOfRepose',
      align: 'center',
    },
    {
      title: 'Height Factor',
      dataIndex: 'heightFactor',
      key: 'heightFactor',
      align: 'center',
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: 'Volume Factor',
      dataIndex: 'volumeFactor',
      key: 'volumeFactor',
      align: 'center',
    },
  ]

  const data = [
    { key: '1', grain: 'Barley', angleOfRepose: 28, heightFactor: '0.2659', volumeFactor: '17.95' },
    { key: '2', grain: 'shelled corn', angleOfRepose: 23, heightFactor: '0.2122', volumeFactor: '22.52' },
    { key: '3', grain: 'oats', angleOfRepose: 28, heightFactor: '0.2659', volumeFactor: '17.95' },
    { key: '4', grain: 'sorghum', angleOfRepose: 29, heightFactor: '0.2772', volumeFactor: '17.24' },
    { key: '5', grain: 'soybeans', angleOfRepose: 25, heightFactor: '0.2332', volumeFactor: '20.49' },
    { key: '6', grain: 'sunflower', angleOfRepose: 28, heightFactor: '0.2659', volumeFactor: '17.95' },
    { key: '7', grain: 'oil sunflowers', angleOfRepose: 27, heightFactor: '0.2548', volumeFactor: '18.76' },
    { key: '8', grain: 'durum wheat', angleOfRepose: 23, heightFactor: '0.2122', volumeFactor: '22.52' },
    { key: '9', grain: 'wheat', angleOfRepose: 25, heightFactor: '0.2332', volumeFactor: '20.49' },
  ]

  return (
    <div>
      <h2 style={{ textAlign: 'center', marginTop: '60px' }}>Height Factor Chart</h2>
      
      <Table
        style={{ marginTop: '10px' }}
        columns={columns}
        dataSource={data}
        bordered
        pagination={false}
      />
      
      <p style={{ textAlign: 'center', marginTop: '10px', fontStyle: 'italic' }}>
        <strong>Note:</strong> This calculator is an estimate only. Actual building capacity may vary. Accu-Steel is not responsible for errors in estimated capacities.
      </p>
    </div>
  )
}

export default function GrainCalculator() {
  return (
    <div>
      <PageHeader title="Grain Storage Calculator" />
      <GrainStorageCalcTable />
      <GrainStorageHeightChart />
    </div>
  )
}